export default {
  title: 'باشگاه ها',

  labels: {
    images: 'تصاویر',
    name: 'نام',
    subdomain: 'آدرس سامانه',
    manager: 'مدیریت',
    sport: 'ورزش',
    country: 'کشور',
    province: 'استان',
    city: 'شهر',
    address: 'آدرس',
    supportNumber: 'شماره پشتیبانی',
    email: 'ایمیل',
    delete: 'حذف'
  },

  table: {
    header: {
      row: 'ردیف',
      logo: 'لوگو',
      name: 'نام',
      manager: 'مدیریت',
      city: 'شهر',
      province: 'استان',
      country: 'کشور',
      supportNumber: 'تلفن پشتیبانی',
      email: 'ایمیل',
      status: 'وضعیت',
      delete: 'حذف',
      restore: 'بازگردانی'
    }
  },

  notifications: {
    wrongInputValue: 'فیلدهای وارد شده صحیح نیستند',

    insert: {
      success: 'باشگاه با موفقیت ایجاد شد',
      error: 'افزودن باشگاه با خطا همراه شد'
    },

    edit: {
      success: 'باشگاه با موفقیت ویرایش شد',
      error: 'ویرایش باشگاه با خطا همراه شد'
    },

    deploy: {
      error: 'راه اندازی سامانه باشگاه با خطا همراه شد'
    },

    delete: {
      success: 'باشگاه با موفقیت حذف شد',
      error: 'حذف باشگاه با خطا همراه شد'
    },

    restore: {
      success: 'باشگاه با موفقیت بازگردانی شد',
      error: 'بازگردانی باشگاه با خطا همراه شد'
    },

    parseError: {
      name: 'نام سامانه معتبر نیست یا قبلا انتخاب شده است',
      subdomain: 'آدرس سامانه معتبر نیست یا قبلا انتخاب شده است',
      sport: 'آدرس سامانه معتبر نیست یا قبلا انتخاب شده است',
      supportNumber: 'شماره پشتیبانی معتبر نیست یا قبلا انتخاب شده است'
    }
  },

  validators: {
    subdomain: 'آدرس سامانه باید انگلیسی باشد',
    subdomainNumber: 'آدرس سامانه نمیتواند با عدد شروع شود',
    subdomainExist: 'آدرس وارد شده قبلا رزرو شده است',
    illegalSubdomain: 'آدرس وارد شده مجاز نیست و یا قبلا رزرو شده است'
  },

  confirmations: {
    delete: {
      title: 'تاییدیه حذف باشگاه',
      body: 'آیا از حذف باشگاه مورد نظر اطمینان دارید؟'
    },

    deploy: {
      title: 'تاییدیه راه اندازی سامانه باشگاه',
      body: 'ایا از راه اندازی سامانه رزرواسیون باشگاه مورد نظر اطمینان دارید؟'
    }
  },

  statusTypes: {
    all: 'همه',
    '0': 'ثبت نام',
    '1': 'تایید اطلاعات',
    '2': 'فعال',
    '3': 'غیرفعال',
    '4': 'نیاز به پرداخت'
  },

  list: {
    title: 'لیست باشگاه ها'
  },

  trash: {
    title: 'سطل زباله باشگاه ها'
  },

  insert: {
    title: 'افزودن باشگاه جدید'
  },

  edit: {
    title: 'ویرایش باشگاه',
    dynamicTitle: 'ویرایش {name}'
  },

  profile: {
    title: 'پروفایل باشگاه',
    dynamicTitle: 'پروفایل {name}',

    importantData: {
      score: 'امتیاز',
      subdomain: 'آدرس سامانه'
    },

    actions: {
      clubCalendar: 'تقویم',
      clubRequests: 'درخواست ها',
      clubReceives: 'دریافت ها',
      clubDeploy: 'راه اندازی سامانه',
      clubLogin: 'ورود به پنل',
      logs: 'لیست لاگ ها'
    }
  },

  calendar: {
    title: 'تقویم باشگاه',

    labels: {
      lastWeek: 'هفته قبل',
      futureWeek: 'هفته بعد',
      timeCourt: 'تایم / زمین',
      confirm: 'لیست تایید رزرو',
    }
  },

  deploy: {
    title: 'راه اندازی سامانه باشگاه',
    dynamicTitle: 'راه اندازی سامانه {name}',

    description: 'برای راه اندازی سامانه باشگاه بر روی راه اندازی سامانه کلیک کنید',

    steps: {
      request_created: 'درخواست ایجاد شد',
      subdomain_created: 'ساب دامنه ایجاد شد',
      database_created: 'دیتابیس ایجاد شد',
      environment_created: 'پیکره بندی ایجاد شد',
      deployed: 'باشگاه راه اندازی شد'
    },

    notifications: {
      success: {
        title: 'سامانه رزرواسیون باشگاه {name} با موفقیت راه اندازی شد',
        body: 'جهت ورود به پنل مدیریت این سامانه، روی دکمه زیر کلیک کنید'
      },
      parseError: {
        hasDeployed: 'پنل مدیریت این باشگاه قبلا راه اندازی شده است'
      }
    },

    actions: {
      deploy: 'راه اندازی سامانه',
      goToClub: 'ورود به پنل'
    }
  },

  requests: {
    title: 'درخواست های باشگاه',

    labels: {
      department: 'دپارتمان',
      type: 'نوع',
      status: 'وضعیت',
      details: 'جزئیات',
      answer: 'پاسخ',
      reject: 'رد درخواست',
      accept: 'تایید'
    },

    table: {
      header: {
        row: 'ردیف',
        date: 'تاریخ',
        department: 'دپارتمان',
        details: 'جزئیات',
        type: 'نوع',
        status: 'وضعیت'
      }
    },

    departmentOptions: {
      all: 'همه',
      1: 'فنی',
      2: 'مالی'
    },

    typeOptions: {
      all: 'همه',
      1: 'بروزرسانی اطلاعات باشگاه'
    },

    statusOptions: {
      all: 'همه',
      0: 'ایجاد شده',
      1: 'در حال بررسی',
      2: 'تایید شده',
      3: 'رد شده'
    },

    notifications: {
      emptyAnswer: 'پاسخی برای درخواست وارد نشده است',

      reject: {
        success: 'درخواست باشگاه با موفقیت رد شد',
        error: 'رد شدن درخواست باشگاه با خطا همراه شد'
      },

      confirm: {
        success: 'درخواست باشگاه با موفقیت پذیرفته شد',
        error: 'پذیرفتن درخواست باشگاه با خطا همراه شد'
      }
    },

    list: {
      title: 'لیست درخواست های باشگاه'
    },

    profile: {
      title: 'درخواست باشگاه'
    },

    clubInfo: {
      title: 'اطلاعات باشگاه',

      labels: {
        generalInfo: 'اطلاعات عمومی',
        name: 'نام',
        locale: 'موقعیت مکانی',
        onlineBooking: 'قابلیت رزرو آنلاین دارد',
        telInfo: 'اطلاعات تماس',
        province: 'استان',
        city: 'شهر',
        address: 'آدرس',
        supportNumber: 'شماره پشتیبانی',
        email: 'ایمیل'
      }
    }
  },

  receives: {
    title: 'دریافت های باشگاه',

    list: {
      title: 'دریافت های باشگاه',
    }
  },

  logs: {
    title: 'لاگ های باشگاه'
  }
}
