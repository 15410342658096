import setting from './setting'
import treasury from './treasury'
import accountancy from './accountancy'
import users from './users'
import sales from './sales'
import logs from './logs'
import activityLogs from './activityLogs'
import clubs from './clubs'
import booking from './booking'

export default {
  // setting page
  setting,
  // Accountancy Pages
  accountancy,
  // Users Page
  users,
  // sales
  sales,
  // treasury
  treasury,
  clubs,
  booking,
  logs,
  activityLogs,

  // navMenuItems Words
  dashboard: 'Dashboard',

  beginning: 'The first course',
  beginningUsers: ' Primary persons',
  beginningCashs: 'Primary fund',
  beginningBanks: 'Primary Bank',
  beginningWarehouse: 'Primary Warehouse',

  purchasesInvoice: 'purchase invoice',

  inventoryValuation: 'Inventory amount review',

  banks: 'Banks',
  cash: 'Cashes',
  transaction: 'Transactions',
  transference: 'Internal Funds Transfer',
  accountingTable: 'Accounts',
  income: 'Income',
  cost: 'Cost',

  accounting: 'Accountancy',

  save: 'Save',
  cancel: 'Cancel',

  logout: 'Logout',

  shortcuts: {
    receive: 'Receive',
    invoice: 'Invoice',
    payment: 'Payment'
  },

  // alert title
  alert: {
    duplicateRequest: {
      title: 'Warning',
      message: 'Your previous request is being processed, please wait'
    },
    error: {
      title: 'Error',
      accessDenied: 'Dont have access to this action'
    },
    warning: {
      title: 'Warning',
      repeatedRequestText: 'Your previous request is being processed, please wait'
    },
    message: {
      title: 'Message',
      pleaseWait: 'Request sent. please wait...'
    }
  },

  // navbar actions
  navbar: {
    back: 'Back',
    save: 'Save',
    dynamicFinancialYear: 'Financial year {start} to {end}',

    notification: {
      newMessage: 'New message',
      showAll: 'View all messages'
    }
  },

  // table statics
  draggableTable: {

    filter: {
      all: 'All',

      types: {
        search: 'Include',
        equals: 'Equal',
        notEqual: 'Not Equal',
        lessThanEquals: 'Less Than Equal',
        greaterThanEquals: 'Greater Than Equal'
      }
    }
  },

  // login page
  login: {
    title: 'Login',

    getPhoneNumberStepMessage1: 'Please enter your phone number. SMS password ',
    getPhoneNumberStepMessage2: '(five-digit code)',
    getPhoneNumberStepMessage3: 'will be sent to confirm your number.',
    phoneNumber: 'Phone Number',
    phoneNumberIsNotValid: 'Phone number not entered correctly',
    phoneNumberIsNull: 'Phone number not entered',

    otpCodeSentStepMessage1: 'for phone number',
    otpCodeSentStepMessage2: 'A 5-digit code was sent. Please enter the code ',
    otpCodeIsNotValid: 'The validation code was not entered correctly',
    otpCodeIsNull: 'Authentication code not entered',
    retrieveTheCode: 'retrieve code',

    loginToPanel: 'Login to panel {name}',
    resendOTPMessage: 'Choose one of the following methods to retrieve the code',
    otpCall: '{number} will be called in a few moments',
    resendOTPTypes: {
      sms: 'SMS',
      tel: 'Contact'
    },

    otp: 'One Time password',
    otpSend: 'One Time password sent to {number}',

    getSuperAdminTokenStepMessage: 'Please enter your token.',
    token: 'system administrator token',

    buttons: {
      getOTPCode: 'Get Code',
      login: 'Login'
    },

    notification: {
      userNotFound: 'User not found with this phone number!',
      accessError: 'Access denied to system!',

      login: {
        title: 'Message',
        message: 'You have successfully logged in'
      },

      logout: {
        title: 'Message',
        message: 'You have successfully logged out'
      },

      forceLogout: {
        title: 'Message',
        message: 'Please log in again due to not using the system for a long time'
      }
    }
  },

  // profile page
  profile: {
    title: 'Profile',
    dynamicTitle: 'Profile {name}',

    details: {
      dynamicCompany: 'Company {company}'
    },

    importantData: {
      balance: 'Balance',
      score: 'Point',
      character: 'User Group'
    },

    actions: {
      transactions: 'Financial Turnover',
      addresses: 'Addresses',
      activeSessions: 'Active sessions',
      receivedEvents: 'Incoming messages',
      sentEvents: 'sent messages',
      customize: 'System Personalization',
    },

    notifications: {
      edit: {
        success: 'Updating the information succeed',
        error: 'Updating the information failed'
      },

      revokeSession: {
        success: 'Active session successfully revoked',
        error: 'Revoke active session has error'
      },

      parseError: {
        required: 'Required Fields are Missing'
      }
    },

    edit: {
      title: 'Edit Profile Information',

      labels: {
        gender: 'Gender',
        base: 'basic',
        name:'First Name',
        family: 'Last Name',
        phoneNumber: 'Phone Number',
        email: 'Email'
      },

      validators: {
        name: 'Name field is not valid',
        family: 'Last Name field is not valid',
        companyName: 'Company Name field is missing',
        phoneNumber: 'Phone Number field is not valid',
        email: 'Email field is not valid'
      }
    },

    address: {
      title: 'Address',

      labels: {
        name: 'Address Name',
        country: 'Country',
        province: 'Province',
        city: 'City',
        address: 'Postal address',
        plaque: 'Plaque',
        postalCode: 'Postal code',
        delete: 'Delete',
        save: 'Save',

        receiver: {
          info: 'Recipient Information',
          name: 'Recipient Name',
          family: 'Recipient Last Name',
          phoneNumber: 'Recipient Phone Number'
        }
      },

      description: {
        noAddress: 'No Addresses registered!'
      },

      validators: {
        city: 'City Name Field is Missing',
        province: 'Province Name Field is Missing',
        name: 'Name Field is Not Valid',
        nameCantEmpty: 'Name Field is Missing',
        address: 'Address Field is Not Valid',
        addressCantEmpty: 'Address Field is Missing',
        plaque: 'Plaque Field is Not Valid',
        postalCode: 'Postal Code Field is Not Valid',
        phoneNumber: 'Phone Number Field is Not Valid',
        receiver: {
          name: 'Recipient Name Field is Not Valid',
          nameCantEmpty: 'Recipient Name Field is Missing',
          family: 'Recipient Last Name Field is Not Valid',
          familyCantEmpty: 'Recipient Last Name Field is Missing',
          phoneNumber: 'Recipient Phone Number Field is Missing'
        }
      },

      notifications: {
        insert: {
          success: 'Creating the address succeed',
          error: 'Creating the address failed'
        },
        edit: {
          success: 'Updating the address succeed',
          error: 'Updating the address failed'
        },
        delete: {
          success: 'Deleting the address succeed',
          error: 'Deleting the address failed'
        }
      },

      insert: {
        title: 'Add new address'
      },

      edit: {
        title: 'Edit Address'
      }
    },

    events: {
      seenTypes: {
        all: 'All',
        seen: 'Viewed',
        unSeen: 'Unseen'
      },

      list: {
        table: {
          header: {
            rowNumber: 'Row',
            creator: 'Sender',
            receiver: 'Receiver',
            subject: 'Subject',
            content: 'Content',
            seen: 'Viewing Status',
            type: 'Type',
            priority: 'Priority',
            createdAt: 'Post Date'
          }
        }
      },

      receive: {
        title: 'Incoming messages'
      },

      send: {
        title: 'Messages sent'
      }
    },

    transactions: {
      title: 'Turnover',

      table: {
        header: {
          docNumber: 'Document Number',
          date: 'Date',
          documentation: 'Documents',
          price: 'Amount',
          balance: 'Inventory'
        }
      }
    },

    activeSessions: {
      title: 'Active sessions',

      labels: {
        maxSessionHelper: 'The maximum number of your active sessions can be {num}!',
        signIn: 'Sign in {date}',
        lastUsed: 'Last accessed on {date}',
        currentSession: 'Online',
        device: '{browser} on {device}',
        revoke: 'Revoke'
      },

      confirmations: {
        revoke: {
          title: 'Revoke active session confirmation',
          body: 'Are you sure to delete your active session with IP {ip}?'
        }
      }
    },

    buttons: {
      save: 'Save Changes',
      reset: 'Undo Changes',
      removeAvatar: 'Delete Photo',
      uploadAvatar: 'Upload Photo'
    },

    General: {
      title: 'General',
      avatar: {
        rule: 'Authorized JPG, JPEG or PNG formats. Maximum photo size 800 KB '
      },
      user: {
        name:'name ',
        lastName:'Last Name',
        role: 'responsibility',
        phoneNumber: 'Mobile Number',
        address: 'Address',
        maxDebt: 'Maximum Debt Rate',
        balance: 'amount of credit'
      }
    },

    MyFinancialReports: {
      title: 'My Financial Statements'
    },

    customize: {
      title: 'System Personalization',

      labels: {
        theme: 'Select the desired theme'
      }
    },
  },

  smsPanel: 'sms panel',

  // opening inventories
  opening: {
    users: {
      title: 'The first period of persons'

    },

    cashBoxes: {
      title: 'The first of the funds'

    },

    banks: {
      title: 'The first period of banks'

    },

    stores: {
      title: 'The first period of warehouses'

    }
  },

  // backup page
  backup: {
    title: 'Backup',

    status: {
      all: 'All',
      saving: 'Saving',
      saved: 'saved',
      retrieved: 'retrieved'
    },

    notifications: {
      insert: {
        success: 'Creating the backup file succeed',
        error: 'Creating the backup file failed'
      },

      recovery: {
        success: 'Sending the request succeed',
        error: 'This backup is not recoverable',
        cantSend: 'Sending the request failed'
      }
    },

    confirmations: {
      insert: {
        title: 'Backup',
        body: 'Are you sure you want to create backup?'
      },

      recovery: {
        title: 'Backup',
        body: 'Are you sure you want to restore the backup?'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'Row',
          date: 'Date',
          status: 'Status',
          recovery: 'Recovery'
        }
      }
    }
  },

  // documents page
  documents: {
    title: 'Documents',

    document: {
      title: 'Document',

      table: {

        header: {
          row: 'Row',
          docNumber: 'Document Number',
          date: 'Date',
          reference: 'Reference',
          details: 'Details',
          price: 'Amount',
          type: 'Type'
        }
      }
    }
  },

  // reports
  reports: {
    title: 'Reports',

    logs: {
      title: 'Logs',

      table: {
        header: {
          rowNumber: 'Row',
          createdAt: 'Log history',
          userName: 'User',
          description: 'Description',
          details: 'Details'
        }
      },

      dialogs: {
        title: 'Log Details',
        withoutDescription: 'No further description'
      },

      list: {
        title: 'List of logs'
      }
    },

    usersTotalSales: {
      title: 'Total sales to individuals',

      table: {
        header: {
          row: 'Row',
          name: 'Name and surname',
          company: 'Company',
          phoneNumber: 'Mobile',
          totalSales: 'Total Sales'
        }
      }
    },

    charts: {
      main: {
        labels: {
          today: 'today',
          week: 'this week',
          month: 'this month',
          year: 'this year',
          sale: 'sale',
          totalSale: 'total',
          personalSale: 'Personal',
          telephoneSale: 'Telephone',
          onlineSale: 'Online',
          grossProfit: 'gross profit',
          default: 'default',
          price: 'price ({currency})'
        }
      },
      assets: {
        title: 'Assets',
        labels: {
          treasury: 'Treasury',
          banks: 'Bank',
          cashes: 'Cash',
          payment_gateways: 'Payment gateway'
        }
      }
    },

    tops: {
      customer: {
        title: 'Top customers',

        labels: {
          profile: 'Profile'
        },

        table: {
          header: {
            row: 'Row',
            name: 'Full name',
            phoneNumber: 'Phone number',
            price: 'Price',
            score: 'Point',
            show: 'Show'
          }
        }
      }
    }
  },

  // tickets
  tickets: {
    title: 'Tickets',

    support: {
      title: 'Support ticket',

      statusTypes: {
        all: 'All',
        created: 'Created',
        customerAnswer: 'Customer response',
        operatorAnswer: 'Operator response',
        pending: 'Awaiting review',
        completed: 'Closed',
        canceled: 'Canceled'
      },

      sendStatusTypes: {
        send: 'Sent',
        notSend: 'Not sent'
      },

      seenStatusTypes: {
        seen: 'Seen',
        notSeen: 'Unseen'
      },

      labels: {
        all: 'All',
        selectType: 'Select the subject',
        service: 'Service',
        subject: 'Ticket subject',
        message: 'Message',
        dynamicAttach: 'Attach {number}',
        insertAttach: 'New attachment',
        unknown: 'Unknown',
        operator: 'Operator'
      },

      placeholder: {
        subject: 'Enter the subject of your ticket',
        message: 'Enter the text of your ticket'
      },

      table: {
        header: {
          row: 'Row',
          department: 'Department',
          service: 'System name',
          subject: 'Subject',
          date: 'Created date',
          lastUpdate: 'Latest update',
          operatorName: 'Operator',
          creator: 'Creator',
          status: 'Status',
          sendStatus: 'Send status',
          seen: 'Seen'
        }
      },

      notifications: {
        attachUpload: 'The file was uploaded',
        requestSend: 'Your request has been sent, please wait!',

        insert: {
          success: 'The ticket was created successfully',
          error: 'Insert ticket has error'
        },

        sendMessage: {
          success: 'The message was sent successfully',
          error: 'Send message has error'
        },

        changeOperator: {
          success: 'Ticket operator edited successfully',
          error: 'Edit ticket operator has error'
        },

        parseError: {
          service: 'The selected service is invalid!',
          subject: 'The selected Subject is not valid!',
          message: 'The text entered is invalid!',
          attachments: 'The selected attachment is invalid!',
          operator: 'The selected operator is not valid!'
        }
      },

      confirmations: {
        send: {
          title: 'Send message confirmation',
          body: 'Are you sure you want to send your message?'
        }
      },

      validators: {
        subject: 'The subject of the ticket was not entered correctly!',
        message: 'The message text was not entered correctly!',
        service: 'The service is not selected correctly!'
      },

      list: {
        title: 'Support tickets'
      },

      insert: {
        title: 'Insert new support ticket'
      },

      answer: {
        title: 'Add reply to support ticket'
      },

      detail: {
        title: 'Support ticket detail',

        labels: {
          ticketId: 'Ticket ',
          status: 'Ticket status: ',
          createdAt: 'Created date: ',
          lastUpdate: 'Last update: ',
          user: 'User',
          operator: 'Operator'
        }
      }
    },

    organization: {
      title: 'Organization ticket',

      statusTypes: {
        all: 'All',
        created: 'Created',
        awaitingReview: 'Awaiting review',
        answered: 'Answered',
        pending: 'Pending',
        completed: 'Completed',
        canceled: 'Canceled'
      },

      sendStatusTypes: {
        send: 'Sent',
        notSend: 'Not sent'
      },

      seenStatusTypes: {
        seen: 'Seen',
        notSeen: 'Unseen'
      },

      labels: {
        all: 'All',
        selectType: 'Select the subject',
        user: 'Receiver',
        subject: 'Ticket subject',
        message: 'Message',
        dynamicAttach: 'Attach {number}',
        insertAttach: 'New attachment',
        unknown: 'Unknown',
        operator: 'Operator'
      },

      placeholder: {
        subject: 'Enter the subject of your ticket',
        message: 'Enter the text of your ticket'
      },

      table: {
        header: {
          row: 'Row',
          creator: 'Creator',
          user: 'Operator',
          subject: 'Subject',
          date: 'Created date',
          lastUpdate: 'Last update',
          status: 'Status'
        }
      },

      notifications: {
        attachUpload: 'The file was uploaded',
        requestSend: 'Your request has been sent, please wait!',

        insert: {
          success: 'The ticket was created successfully',
          error: 'Insert ticket has error'
        },

        sendMessage: {
          success: 'The message was sent successfully',
          error: 'Send message has error'
        },

        complete: {
          success: 'The ticket was completed successfully',
          error: 'Complete ticket has error'
        },

        changeOperator: {
          success: 'Ticket operator edited successfully',
          error: 'Edit Ticket operator has error'
        },

        parseError: {
          user: 'The selected receiver is not valid!',
          service: 'The selected service is invalid!',
          subject: 'The selected Subject is not valid!',
          message: 'The text entered is invalid!',
          attachments: 'The selected attachment is invalid!',
          operator: 'The selected operator is not valid!'
        }
      },

      confirmations: {
        send: {
          title: 'Send message confirmation',
          body: 'Are you sure you want to send your message?'
        }
      },

      validators: {
        user: 'The receiver of the ticket was not entered correctly!',
        subject: 'The subject of the ticket was not entered correctly!',
        message: 'The message text was not entered correctly!',
        service: 'The service is not selected correctly!'
      },

      list: {
        title: 'Organization tickets'
      },

      insert: {
        title: 'Insert new organization ticket'
      },

      answer: {
        title: 'Add reply to organization ticket'
      },

      detail: {
        title: 'Organization ticket detail',

        labels: {
          ticketId: 'Ticket id',
          status: 'Ticket Status: ',
          createdAt: 'Created date: ',
          lastUpdate: 'Last update: ',
          user: 'User',
          operator: 'Operator',
          setComplete: 'Resolved problem'
        }
      }
    }
  },

  // events
  events: {
    title: 'Announcements',

    labels: {
      priority: 'priority',
      type: 'subject',
      subject: 'subject',
      content: 'Text',
      bySystem: 'By system'
    },

    eventTypes: {
      all: 'All',
      update: 'System Update',
      activity: 'Activity',
      message: 'message',
      warning: 'warning'
    },

    priority: {
      all: 'All',
      veryLow: 'Very little',
      low: 'low',
      medium: 'medium',
      high: 'many',
      veryHigh: 'Too much'
    },

    validators: {
      subject: 'Subject not entered correctly',
      content: 'Content not entered correctly'
    },

    notifications: {
      insert: {
        success: 'Message sent successfully',
        error: 'Message sent failed'
      },

      parseError: {
        type: 'Type not selected',
        priority: 'Priority not selected'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'Row',
          sender: 'Sender',
          subject: 'Subject',
          content: 'Content',
          type: 'Type',
          priority: 'Priority',
          sendDate: 'Send Date'
        }
      }
    },

    insert: {
      title: 'Send New Announcements'
    },

    event: {
      labels: {
        understand: 'I realized'
      }
    }
  },

  // draggable dynamic table
  draggableDynamicTable: {

    filters: 'filters',

    activeFilters: {
      title: 'Applied filters'
    },

    setting: {
      showColumns: 'Show Columns'
    },

    notifications: {
      loading: 'Loading information ...',
      error: 'Error receiving information',
      empty: 'No information available'
    },

    actions: {
      print: 'Print',
      download: 'Download',
      setting: 'Table settings'
    }
  },

  // accessTreeCheckBox
  accessTreeCheckBox: {
    labels: {
      chooseCategory: 'Choose access'
    }
  },

  // custom dialog
  customDialog: {
    confirm: 'Confirm',
    cancel: 'Cancel'
  },

  // custom file input
  customFileInput: {
    uploaded: 'Your photo has been uploaded',
    sizeError: 'File size must be less than {size} kilobytes!'
  },

  // customProfileImageInput
  customProfileImageInput: {
    validators: {
      imageSize: 'The image size must be less than {size} kilobytes!'
    }
  },

  // custom date picker input
  customDatePickerInput: {
    invalidText: 'The entered field is not valid'
  },

  // contextMenu
  contextMenu: {
    actions: {
      openNewTab: 'Open in new tab',
      copyLink: 'Copy link address',
      copy: 'Copy',
      paste: 'Paste'
    },
    notifications: {
      clipboard: 'Copied to clipboard!'
    }
  },

  // date picker
  datePicker: {
    labels: {
      submit: 'Confirm',
      cancel: 'cancel',
      now: 'now',
      nextMonth: 'next month',
      prevMonth: 'month ago'
    },

    formats: {
      date: 'jYYYY/jMM/jDD',
      dateTime: 'jYYYY/jMM/jDD HH:mm',
      time: 'HH:mm'
    }
  },

  // Button
  create: 'Add',

  // mixins
  documentDescriptions: {
    receive: {
      description: 'Receive document No. {id}',
      dynamicDescription: 'Receive document | {description}'
    },
    clubReceive: {
      description: 'Club receive document No. {id}',
      dynamicDescription: 'Club receive document | {description}'
    },
    payment: {
      description: 'Payment document No. {id}',
      dynamicDescription: 'Payment document | {description}'
    },
    cost: {
      description: 'Cost document No. {id}',
      dynamicDescription: 'Cost document | {description}'
    },
    beginningInventory: {
      description: 'Beginning inventory document'
    },
    internalFundTransfer: {
      description: 'Withdrawal from {payer} Deposit to {payee}'
    },
    receivePaymentGateway: {
      description: 'Receive payment gateway from {payer} Deposit to {payee}'
    },
    sale: {
      description: 'Sale invoice No. {id}'
    },
    returnSale: {
      description: 'Return sale invoice No. {id}'
    },
    purchase: {
      description: 'Purchase invoice No. {id}'
    },
    returnPurchase: {
      description: 'Return purchase invoice No. {id}'
    },
    warehouse_receipt: {
      on_way_description: 'Warehouse receipt for {invoice_type} No. {invoice_id}',
      description: 'Warehouse receipt No. {receipt_id}'
    },
    inventory_transfer: {
      description: 'Warehouse transfer No. {receipt_id}'
    },
    warehouse_requisition: {
      waiting_to_send_description: 'Waiting to send warehouse requisition for {invoice_type} No. {invoice_id}',
      description: 'Warehouse requisition No. {receipt_id}',
      description_dynamic: 'Warehouse requisition {receipt_id} for {invoice_type} No. {invoice_id}'
    }
  },

  countries: {
    IR: {
      name: 'IRAN',
      provinces: {
        EAZ: 'East Azerbaijan',
        WAZ: 'West Azerbaijan',
        ADL: 'Ardabil',
        ESF: 'Isfahan',
        ABZ: 'Alborz',
        ILM: 'Ilam',
        BHR: 'Bushehr',
        THR: 'Tehran',
        SKH: 'South Khorasan',
        RKH: 'Razavi Khorasan',
        NKH: 'North Khorasan',
        KHZ: 'Khuzestan',
        ZJN: 'Zanjan',
        SMN: 'Semnan',
        SBN: 'Sistan and Baluchestan',
        FRS: 'Fars',
        GZN: 'Qazvin',
        QHM: 'Qom',
        KRD: 'Kurdistan',
        KRN: 'Kerman',
        KRH: 'Kermanshah',
        KBD: 'Kohgiluyeh and Boyer-Ahmad',
        GLS: 'Golestan',
        GIL: 'Gilan',
        LRS: 'Lorestan',
        MZN: 'Mazandaran',
        MKZ: 'Markazi',
        HRZ: 'Hormozgan',
        HDN: 'Hamadan',
        CHB: 'Chaharmahal and Bakhtiari',
        YZD: 'Yazd'
      }
    },
    AE: {
      name: 'United Arab Emirates',
      provinces: {
        AE_AA: 'Al Ain',
        AE_AZ: 'Abu Dhabi Emirate',
        AE_AJ: 'Ajman Emirate',
        AE_DU: 'Dubai',
        AE_FU: 'Fujairah',
        AE_RK: 'Ras al-Khaimah',
        AE_SH: 'Sharjah Emirate',
        AE_UQ: 'Umm al-Quwain'
      }
    },
    KW: {
      name: 'Kuwait',
      provinces: {
        KW_AA: 'Al Asimah',
        KW_HA: 'Hawalli',
        KW_AF: 'Al Farwaniyah',
        KW_MK: 'Mubarak Al-Kabeer',
        KW_AJ: 'Al Jahra',
        KW_AH: 'Al Ahmadi'
      }
    },
    SA: {
      name: 'Saudi Arabia',
      provinces: {
        SA_RH: 'Riyadh',
        SA_MH: 'Makkah',
        SA_AM: 'Al Madinah',
        SA_EP: 'Eastern Province',
        SA_AQ: 'Al-Qassim',
        SA_HI: 'Ha\'il',
        SA_TK: 'Tabuk',
        SA_NB: 'Northern Borders',
        SA_JN: 'Jazan',
        SA_NN: 'Najran',
        SA_AB: 'Al-Bahah Region',
        SA_AJ: 'Al-Jawf',
        SA_AR: 'Asir'
      }
    },
    OM: {
      name: 'Oman',
      provinces: {
        OM_MC: 'Muscat',
        OM_AD: 'Ad Dakhiliyah',
        OM_BN: 'Al Batinah North',
        OM_BS: 'Al Batinah South',
        OM_BU: 'Al Buraymi',
        OM_DH: 'Ad Dhahirah',
        OM_WS: 'Al Wusta',
        OM_MN: 'Musandam',
        OM_SN: 'Ash Sharqiyah North',
        OM_SS: 'Ash Sharqiyah South',
        OM_DO: 'Dhofar'
      }
    },
    BH: {
      name: 'Bahrain',
      provinces: {
        BH_CL: 'Capital',
        BH_SN: 'Southern',
        BH_NN: 'Northern'
      }
    },
    QA: {
      name: 'Qatar',
      provinces: {
        QA_AS: 'Al Shamal',
        QA_AK: 'Al Khor',
        QA_AH: 'Al Shahaniya',
        QA_US: 'Umm Salal',
        QA_AD: 'Al Daayen',
        QA_AW: 'Ad Dawhah',
        QA_AR: 'Al Rayyan',
        QA_AA: 'Al Wakrah'
      }
    }
  }
}
