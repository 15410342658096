export default {
  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        user: 'کاربر',
        description: 'توضیحات',
        date: 'تاریخ'
      }
    },

    dialogs: {
      title: 'توضیحات',

      noChange: 'فیلدی تغییری داده نشده است'
    }
  },

  login: 'کاربر وارد سامانه شد',
  logout: 'کاربر از سامانه خارج شد',
  register: 'کاربر در سامانه ثبت نام شد',
  reset_app: 'سامانه بازنشانی شد',

  user: {
    store: 'اطلاعات کاربر ایجاد شد',
    update: 'اطلاعات کاربر به روزرسانی شد',
    soft_destroy: 'اطلاعات کاربر حذف موقت شد',
    restore: 'اطلاعات کاربر بازگردانی شد',
    destroy: 'اطلاعات کاربر حذف شد',
    set_role: 'به کاربر نقش تخصیص داده شد',
    set_max_debt: 'سقف بدهکاری شخص به روز رسانی شد'
  },

  user_address: {
    store: 'آدرس جدید به کاربر اضافه شد',
    update: 'آدرس کاربر به روز شد',
    destroy: 'آدرس کاربر حذف شد'
  },

  club: {
    store: 'اطلاعات باشگاه ایجاد شد',
    update: 'اطلاعات باشگاه به روزرسانی شد',
    soft_destroy: 'اطلاعات باشگاه حذف موقت شد',
    restore: 'اطلاعات باشگاه بازگردانی شد',
    destroy: 'اطلاعات باشگاه حذف شد',
    deploy: 'اطلاعات باشگاه دیپلوی شد',
    login: 'کاربر وارد پنل مدیریت باشگاه شد'
  },

  sale_plan: {
    store: 'اطلاعات پلن فروش ایجاد شد',
    update: 'اطلاعات پلن فروش به روزرسانی شد',
    soft_destroy: 'اطلاعات پلن فروش حذف موقت شد',
    restore: 'اطلاعات پلن فروش بازگردانی شد',
    destroy: 'اطلاعات پلن فروش حذف شد',
    set_permission: 'دسترسی برای پلن فروش ست شد'
  },

  sport: {
    store: 'اطلاعات ورزش ایجاد شد',
    update: 'اطلاعات ورزش به روزرسانی شد',
    soft_destroy: 'اطلاعات ورزش حذف موقت شد',
    restore: 'اطلاعات ورزش بازگردانی شد',
    destroy: 'اطلاعات ورزش حذف شد'
  },

  invoice: {
    store: 'اطلاعات فاکتور ایجاد شد',
    update: 'اطلاعات فاکتور بروزرسانی شد',
    force_update: 'اطلاعات فاکتور به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور بازگردانی شد',
    destroy: 'اطلاعات فاکتور حذف شد',
    complete: 'فاکتور پرداخت شد',
    refresh_status: 'وضعیت فاکتور تغییر داده شد'
  },

  role: {
    store: 'گروه دسترسی ایجاد شد',
    update: 'گروه دسترسی بروزرسانی شد',
    destroy: 'گروه دسترسی حذف شد'
  },

  receive: {
    store: 'رسید دریافت ایجاد شد',
    update: 'رسید دریافت بروزرسانی شد',
    destroy: 'رسید دریافت حذف شد'
  },

  payment: {
    store: 'رسید پرداخت ایجاد شد',
    update: 'رسید پرداخت بروزرسانی شد',
    destroy: 'رسید پرداخت حذف شد'
  },

  internal_fund: {
    store: 'رسید انتقالی خزانه ایجاد شد',
    update: 'رسید انتقالی خزانه بروزرسانی شد',
    destroy: 'رسید انتقالی خزانه حذف شد'
  },

  cost: {
    store: 'رسید هزینه ایجاد شد',
    update: 'رسید هزینه بروزرسانی شد',
    destroy: 'رسید هزینه حذف شد'
  },

  bank: {
    store: 'اطلاعات بانک ایجاد شد',
    update: 'اطلاعات بانک بروزرسانی شد',
    soft_destroy: 'اطلاعات بانک به صورت موقت حذف شد',
    restore: 'اطلاعات بانک بازگردانی شد',
    destroy: 'اطلاعات بانک حذف شد'
  },

  cash: {
    store: 'اطلاعات صندوق ایجاد شد',
    update: 'اطلاعات صندوق بروزرسانی شد',
    soft_destroy: 'اطلاعات صندوق به صورت موقت حذف شد',
    restore: 'اطلاعات صندوق بازگردانی شد',
    destroy: 'اطلاعات صندوق حذف شد'
  },

  payment_gateway: {
    store: 'اطلاعات درگاه پرداخت ایجاد شد',
    update: 'اطلاعات درگاه پرداخت بروزرسانی شد',
    soft_destroy: 'اطلاعات درگاه پرداخت به صورت موقت حذف شد',
    restore: 'اطلاعات درگاه پرداخت بازگردانی شد',
    destroy: 'اطلاعات درگاه پرداخت حذف شد'
  },

  variablesLabel: {
    sale_plan: {
      public: {
        true: 'Enable',
        false: 'Disable'
      }
    }
  },

  changeFields: {
    store: {
      user: {
        name: 'نام «{new}» ثبت شد.',
        family: 'نام خانوادگی «{new}» ثبت شد.',
        gender: 'جنسیت «{new}» ثبت شد.',
        avatar: 'عکس پروفایل کاربر ثبت شد',
        company: 'شرکت «{new}» ثبت شد.',
        phone_number: 'شماره تلفن «{new}» ثبت شد.',
        email: 'ایمیل «{new}» ثبت شد.',
        max_debt: 'سقف بدهکاری «{new}» ثبت شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          province: 'استان «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          title: 'عنوان «{new}» ثبت شد.'
        }
      },

      club: {
        avatar: 'لوگوی باشگاه ثبت شد',
        name: 'نام «{new}» ثبت شد.',
        sport: 'ورزش «{new}» ثبت شد.',
        manager: 'مدیریت «{new}» ثبت شد.',
        sale_plan: 'پلن فروش «{new}» ثبت شد.',
        city: 'شهر «{new}» ثبت شد.',
        province: 'استان «{new}» ثبت شد.',
        address: 'آدرس «{new}» ثبت شد.',
        support_number: 'شماره پشتیبانی «{new}» ثبت شد.',
        email: 'ایمیل «{new}» ثبت شد.',
        subdomain: 'آدرس سامانه «{new}» ثبت شد.',
        club_locale: 'منطقه باشگاه «{new}» ثبت شد.',
        enable_online_booking: 'وضعیت رزرو آنلاین «{new}» ثبت شد.',
        score: 'امتیاز «{new}» ثبت شد.',
        images: {
          path: 'عکس باشگاه ثبت شد'
        }
      },

      sale_plan: {
        avatar: 'لوگوی باشگاه ثبت شد',
        name: 'نام «{new}» ثبت شد.',
        code: 'کد «{new}» ثبت شد.',
        public: 'وضعیت عمومی بودن پلن «{new}» ثبت شد.',
        period: 'مدت دوره «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        sale_price: 'قیمت حراج «{new}» ثبت شد.',
        re_purchase: 'تمدید خودکار «{new}» ثبت شد.'
      },

      sport: {
        logo: 'لوگوی ورزش ثبت شد',
        name: 'نام «{new}» ثبت شد.',
        slug: 'آدرس «{new}» ثبت شد.'
      },

      bank: {
        name: 'نام «{new}» ثبت شد.',
        card_number: 'شماره کارت «{new}» ثبت شد.',
        account_number: 'شماره حساب «{new}» ثبت شد.',
        shaba_number: 'شماره شبا «{new}» ثبت شد.'
      },

      cash: {
        name: 'نام «{new}» ثبت شد.'
      },

      payment_gateway: {
        type: 'نوع درگاه پرداخت «{new}» ثبت شد.',
        title: 'عنوان درگاه «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        bank: 'بانک تسویه «{new}» ثبت شد.',
        enabled: 'وضعیت درگاه پرداخت «{new}» ثبت شد.',
        end_time: 'ساعت پایان روز کاری «{new}» ثبت شد.',
        merchant_code: 'کد پذیرنده «{new}» ثبت شد.',
        terminal_code: 'کد پایانه «{new}» ثبت شد.'
      },

      cost: {
        payer: 'طرف حساب «{new}» ثبت شد.',
        account: 'سرفصل هزینه «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      internal_fund: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        wage: 'کارمزد «{new}» ثبت شد.',
        reference: 'شماره مرجع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      payment: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      receive: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        reference: 'شماره مرجع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      role: {
        name: 'نام «{new}» ثبت شد.',
        parent: 'والد «{new}» ثبت شد.',
        allowed_active_sessions: 'تعداد نشست های فعال «{new}» ثبت شد.',
        permissions: {
          id: 'شناسه دسترسی «{new}» ثبت شد.',
          name: 'نام دسترسی «{new}» ثبت شد.',
          slug: 'نامک دسترسی «{new}» ثبت شد.'
        }
      },

      invoice: {
        user: 'طرف حساب «{new}» ثبت شد.',
        invoice_number: 'شماره فاکتور «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        action_type: 'نوع رزرو «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        invoice_date: 'تاریخ فاکتور «{new}» ثبت شد.',
        details: 'جزئیات «{new}» ثبت شد.',
        note: 'یادداشت «{new}» ثبت شد.',
        shipping: 'هزینه حمل و نقل «{new}» ثبت شد.',
        final_price: 'قیمت نهایی «{new}» ثبت شد.',
        address: {
          province: 'استان «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          address: 'آدرس «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.'
        },
        lines: {
          product_variant: 'متغیر محصول «{new}» ثبت شد.',
          storeroom: 'انبار «{new}» ثبت شد.',
          unit_price: 'قیمت واحد «{new}» ثبت شد.',
          quantity: 'تعداد محصول «{new}» ثبت شد.',
          discount: 'تخفیف «{new}» ثبت شد.',
          tax: 'مالیات «{new}» ثبت شد.'
        },
        services: {
          service: 'خدمت «{new}» ثبت شد.',
          quantity: 'تعداد «{new}» ثبت شد.',
          required: 'اجباری بودن خدمت «{new}» ثبت شد.',
          unit_price: 'قیمت واحد «{new}» ثبت شد.',
          discount: 'تخفیف «{new}» ثبت شد.',
          cancel_price: 'قیمت لغو کردن «{new}» ثبت شد.',
          vat: 'مالیات «{new}» ثبت شد.'
        }
      }
    },

    update: {
      user: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        family: 'نام خانوادگی از «{old}» به «{new}» بروزرسانی شد.',
        gender: 'جنسیت از «{old}» به «{new}» بروزرسانی شد.',
        avatar: 'عکس پروفایل کاربر بروزرسانی شد',
        company: 'شرکت از «{old}» به «{new}» بروزرسانی شد.',
        phone_number: 'شماره تلفن از «{old}» به «{new}» بروزرسانی شد.',
        email: 'ایمیل از «{old}» به «{new}» بروزرسانی شد.',
        max_debt: 'سقف بدهکاری از «{old}» به «{new}» بروزرسانی شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          title: 'عنوان از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      club: {
        avatar: 'لوگوی باشگاه بروزرسانی شد',
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        sport: 'ورزش از «{old}» به «{new}» بروزرسانی شد.',
        manager: 'مدیریت از «{old}» به «{new}» بروزرسانی شد.',
        sale_plan: 'پلن فروش از «{old}» به «{new}» بروزرسانی شد.',
        city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
        province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
        address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
        support_number: 'شماره پشتیبانی از «{old}» به «{new}» بروزرسانی شد.',
        email: 'ایمیل از «{old}» به «{new}» بروزرسانی شد.',
        subdomain: 'آدرس سامانه از «{old}» به «{new}» بروزرسانی شد.',
        club_locale: 'منطقه باشگاه از «{old}» به «{new}» بروزرسانی شد.',
        enable_online_booking: 'وضعیت رزرو آنلاین از «{old}» به «{new}» بروزرسانی شد.',
        score: 'امتیاز از «{old}» به «{new}» بروزرسانی شد.',
        images: {
          path: 'عکس باشگاه بروزرسانی شد'
        }
      },

      sale_plan: {
        avatar: 'لوگوی پلن فروش بروزرسانی شد',
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        code: 'کد از «{old}» به «{new}» بروزرسانی شد.',
        public: 'وضعیت عمومی بودن از «{old}» به «{new}» بروزرسانی شد.',
        period: 'مدت پلن فروش از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        sale_price: 'قیمت حراج از «{old}» به «{new}» بروزرسانی شد.',
        re_purchase: 'وضعیت تمدید خودکار از «{old}» به «{new}» بروزرسانی شد.'
      },

      sport: {
        logo: 'لوگوی ورزش بروزرسانی شد',
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        slug: 'آدرس از «{old}» به «{new}» بروزرسانی شد.'
      },

      bank: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        card_number: 'شماره کارت از «{old}» به «{new}» بروزرسانی شد.',
        account_number: 'شماره حساب از «{old}» به «{new}» بروزرسانی شد.',
        shaba_number: 'شماره شبا از «{old}» به «{new}» بروزرسانی شد.'
      },

      cash: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.'
      },

      payment_gateway: {
        type: 'نوع درگاه پرداخت بروزرسانی شد',
        title: 'عنوان درگاه از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        bank: 'بانک تسویه از «{old}» به «{new}» بروزرسانی شد.',
        enabled: 'وضعیت درگاه پرداخت بروزرسانی شد.',
        end_time: 'ساعت پایان روز کاری از «{old}» به «{new}» بروزرسانی شد.',
        merchant_code: 'کد پذیرنده از «{old}» به «{new}» بروزرسانی شد.',
        terminal_code: 'کد پایانه از «{old}» به «{new}» بروزرسانی شد.'
      },

      cost: {
        payer: 'طرف حساب از «{old}» به «{new}» بروزرسانی شد.',
        account: 'سرفصل هزینه از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      internal_fund: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        wage: 'کارمزد از «{old}» به «{new}» بروزرسانی شد.',
        reference: 'شماره مرجع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      payment: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      receive: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        reference: 'شماره مرجع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      role: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        parent: 'والد از «{old}» به «{new}» بروزرسانی شد.',
        allowed_active_sessions: 'تعداد نشست های فعال از «{old}» به «{new}» بروزرسانی شد.',
        permissions: {
          id: 'شناسه دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          name: 'نام دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          slug: 'نامک دسترسی از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      invoice: {
        user: 'طرف حساب از «{old}» به «{new}» بروزرسانی شد.',
        invoice_number: 'شماره فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        action_type: 'نوع رزرو از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        invoice_date: 'تاریخ فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        details: 'جزئیات از «{old}» به «{new}» بروزرسانی شد.',
        note: 'یادداشت از «{old}» به «{new}» بروزرسانی شد.',
        shipping: 'هزینه حمل و نقل از «{old}» به «{new}» بروزرسانی شد.',
        final_price: 'قیمت نهایی از «{old}» به «{new}» بروزرسانی شد.',
        address: {
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.'
        },
        lines: {
          product_variant: 'متغیر محصول از «{old}» به «{new}» بروزرسانی شد.',
          storeroom: 'انبار از «{old}» به «{new}» بروزرسانی شد.',
          unit_price: 'قیمت واحد از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد محصول از «{old}» به «{new}» بروزرسانی شد.',
          discount: 'تخفیف از «{old}» به «{new}» بروزرسانی شد.',
          tax: 'مالیات از «{old}» به «{new}» بروزرسانی شد.'
        },
        services: {
          service: 'خدمت از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد از «{old}» به «{new}» بروزرسانی شد.',
          required: 'اجباری بودن خدمت از «{old}» به «{new}» بروزرسانی شد.',
          unit_price: 'قیمت واحد از «{old}» به «{new}» بروزرسانی شد.',
          discount: 'تخفیف از «{old}» به «{new}» بروزرسانی شد.',
          cancel_price: 'قیمت لغو کردن از «{old}» به «{new}» بروزرسانی شد.',
          vat: 'مالیات از «{old}» به «{new}» بروزرسانی شد.'
        }
      }
    }
  }
}
