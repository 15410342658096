export default {
  title: 'رزرواسیون',

  salePlan: {
    title: 'پلن فروش',

    labels: {
      code: 'کد',
      name: 'نام',
      period: 'مدت زمان پلن',
      price: 'قیمت',
      salePrice: 'قیمت حراج',
      public: 'پلن عمومی است',
      rePurchase: 'پلن امکان فعال سازی مجدد دارد',
      delete: 'حذف'
    },

    table: {
      header: {
        row: 'ردیف',
        code: 'کد',
        name: 'نام',
        period: 'دوره',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    notifications: {
      wrongInputValue: 'اطلاعات وارد شده صحیح نیست',
      insert: {
        success: 'پلن فروش با موفقیت ایجاد شد',
        error: 'ایجاد پلن فروش با خطا همراه شد'
      },
      edit: {
        success: 'پلن فروش با موفقیت بروزرسانی شد',
        error: 'بروزرسانی پلن فروش با خطا همراه شد'
      },
      delete: {
        success: 'پلن فروش با موفقیت حذف شد',
        error: 'حذف پلن فروش با خطا همراه شد'
      },
      restore: {
        success: 'پلن فروش با موفقیت بازگردانی شد',
        error: 'بازگردانی پلن فروش با خطا همراه شد'
      },
      editLimits: {
        success: 'محدودیت های پلن فروش با موفقیت بروزرسانی شد',
        error: 'بروزرسانی محدودیت های پلن فروش با خطا همراه شد'
      },
      parseError: {
        name: 'نام وارد شده معتبر نیست',
        code: 'کد وارد شده معتبر نیست یا قبلا استفاده شده است'
      }
    },

    validators: {
      permissions: 'دسترسی برای پلن ست نشده است'
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف پلن فروش',
        body: 'آیا از حذف پلن فروش مورد نظر اطمینان دارید؟'
      }
    },

    list: {
      title: 'پلن های فروش'
    },

    trash: {
      title: 'سطل زباله پلن های فروش'
    },

    insert: {
      title: 'افزودن پلن فروش'
    },

    edit: {
      title: 'ویرایش پلن فروش',
      dynamicTitle: 'ویرایش پلن {name}'
    },

    profile: {
      title: 'پروفایل پلن فروش',
      dynamicTitle: 'پروفایل پلن {name}',

      importantData: {
        code: 'کد',
        price: 'قیمت'
      },

      actions: {
        clubs: 'باشگاه ها',
        limits: 'محدودیت ها',
        logs: 'لیست لاگ ها'
      }
    },

    clubs: {
      title: 'لیست باشگاه های پلن فروش'
    },

    limits: {
      title: 'محدودیت های پلن فروش',

      labels: {
        inMenu: 'منو'
      }
    },

    logs: {
      title: 'لاگ های پلن فروش'
    }
  },

  sports: {
    title: 'ورزش ها',

    labels: {
      name: 'نام',
      slug: 'آدرس',
      delete: 'حذف'
    },

    table: {
      header: {
        select: 'انتخاب',
        row: 'ردیف',
        logo: 'لوگو',
        name: 'نام',
        slug: 'آدرس',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    notifications: {
      wrongInputValue: 'اطلاعات وارد شده صحیح نیست',
      sportUsed: 'ورزش مورد نظر در سامانه استفاده شده است و قابل حذف نیست',
      insert: {
        success: 'ورزش با موفقیت ایجاد شد',
        error: 'ایجاد ورزش با خطا همراه شد'
      },
      edit: {
        success: 'ورزش با موفقیت بروزرسانی شد',
        error: 'بروزرسانی ورزش با خطا همراه شد'
      },
      delete: {
        success: 'ورزش با موفقیت حذف شد',
        error: 'حذف ورزش با خطا همراه شد'
      },
      restore: {
        success: 'ورزش با موفقیت بازگردانی شد',
        error: 'بازگردانی ورزش با خطا همراه شد'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف ورزش',
        body: 'آیا از حذف ورزش مورد نظر اطمینان دارید؟'
      }
    },

    validators: {
      slug: 'آدرس باید انگلیسی باشد',
      slugNumber: 'آدرس نمیتواند با عدد شروع شود'
    },

    list: {
      title: 'لیست ورزش ها'
    },

    select: {
      title: 'لیست ورزش ها'
    },

    trash: {
      title: 'سطل زباله ورزش ها'
    },

    insert: {
      title: 'افزودن ورزش جدید'
    },

    edit: {
      title: 'ویرایش ورزش'
    },

    profile: {
      title: 'پروفایل ورزش',
      dynamicTitle: 'پروفایل {name}',

      importantData: {
        slug: 'آدرس'
      },

      actions: {
        clubs: 'باشگاه ها',
        logs: 'لیست لاگ ها'
      }
    },

    clubs: {
      title: 'لیست باشگاه های ورزش'
    },

    logs: {
      title: 'لاگ های ورزش'
    }
  }
}