export default {
  list: {
    table: {
      header: {
        rowNumber: 'Row',
        user: 'User',
        description: 'Description',
        date: 'Date'
      }
    },

    dialogs: {
      title: 'Descriptions',

      noChange: 'No field change'
    }
  },

  login: 'User login',
  logout: 'User logout',
  register: 'User register',
  reset_app: 'Reset app',

  user: {
    store: 'User info created',
    update: 'User info updated',
    soft_destroy: 'User info temporary deleted',
    restore: 'User info restored',
    destroy: 'User info destroyed',
    set_role: 'Set role to user',
    set_max_debt: 'Set max debt to user'
  },

  user_address: {
    store: 'User address created',
    update: 'User address updated',
    destroy: 'User address deleted'
  },

  club: {
    store: 'Club info created',
    update: 'Club info updated',
    soft_destroy: 'Club info temporary deleted',
    restore: 'Club info restored',
    destroy: 'Club info destroyed',
    deploy: 'Club info deployed',
    login: 'User login to club panel'
  },

  sale_plan: {
    store: 'Sale plan info created',
    update: 'Sale plan info updated',
    soft_destroy: 'Sale plan info temporary deleted',
    restore: 'Sale plan info restored',
    destroy: 'Sale plan info destroyed',
    set_permission: 'Set permission for sale plan'
  },

  sport: {
    store: 'Sport info created',
    update: 'Sport info updated',
    soft_destroy: 'Sport info temporary deleted',
    restore: 'Sport info restored',
    destroy: 'Sport info destroyed'
  },

  invoice: {
    store: 'Invoice created',
    update: 'Invoice info updated',
    force_update: 'Invoice info force updated',
    soft_destroy: 'Invoice info temporary deleted',
    restore: 'Invoice info restored',
    destroy: 'Invoice info deleted',
    complete: 'Invoice payed',
    refresh_status: 'Invoice refresh status'
  },

  role: {
    store: 'Role created',
    update: 'Role info updated',
    destroy: 'Role info deleted'
  },

  receive: {
    store: 'Receive document created',
    update: 'Receive document updated',
    destroy: 'Receive document deleted'
  },

  payment: {
    store: 'Payment document created',
    update: 'Payment document updated',
    destroy: 'Payment document deleted'
  },

  internal_fund: {
    store: 'Internal fund document created',
    update: 'Internal fund document updated',
    destroy: 'Internal fund document deleted'
  },

  cost: {
    store: 'Cost document created',
    update: 'Cost document updated',
    destroy: 'Cost document deleted'
  },

  bank: {
    store: 'Bank info created',
    update: 'Bank info updated',
    soft_destroy: 'Bank info temporary deleted',
    restore: 'Bank info restored',
    destroy: 'Bank info deleted'
  },

  cash: {
    store: 'Cash info created',
    update: 'Cash info updated',
    soft_destroy: 'Cash info temporary deleted',
    restore: 'Cash info restored',
    destroy: 'Cash info deleted'
  },

  payment_gateway: {
    store: 'Payment gateway info created',
    update: 'Payment gateway info updated',
    soft_destroy: 'Payment gateway info temporary deleted',
    restore: 'Payment gateway info restored',
    destroy: 'Payment gateway info deleted'
  },

  variablesLabel: {
    sale_plan: {
      public: {
        true: 'فعال',
        false: 'غیرفعال'
      }
    }
  },

  changeFields: {
    store: {
      user: {
        name: 'Name set to "{new}".',
        family: 'Family set to "{new}".',
        gender: 'Gender set to "{new}".',
        avatar: 'User Avatar stored',
        company: 'Company set to "{new}".',
        phone_number: 'Phone number set to "{new}".',
        email: 'Email set to "{new}".',
        max_debt: 'Max debt set to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address set to "{new}".',
          city: 'City set to "{new}".',
          province: 'Province set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          title: 'Title set to "{new}".'
        }
      },

      club: {
        avatar: 'Club logo stored',
        name: 'Name set to "{new}".',
        sport: 'Sport set to "{new}".',
        manager: 'Manager set to "{new}".',
        sale_plan: 'Sale plan set to "{new}".',
        city: 'City set to "{new}".',
        province: 'Province set to "{new}".',
        address: 'Address set to "{new}".',
        support_number: 'Support number set to "{new}".',
        email: 'Email set to "{new}".',
        subdomain: 'Subdomain set to "{new}".',
        club_locale: 'Locale set to "{new}".',
        enable_online_booking: 'Enable online booking set to "{new}".',
        score: 'Score set to "{new}".',
        images: {
          path: 'Club image stored'
        }
      },

      sale_plan: {
        avatar: 'Sale plan logo stored',
        name: 'Name set to "{new}".',
        code: 'Code set to "{new}".',
        public: 'Public status set to "{new}".',
        period: 'Period set to "{new}".',
        price: 'Price set to "{new}".',
        sale_price: 'Sale price set to "{new}".',
        re_purchase: 'Re-purchase status set to "{new}".'
      },

      sport: {
        logo: 'Sport logo stored',
        name: 'Name set to "{new}".',
        slug: 'Slug set to "{new}".'
      },

      bank: {
        name: 'Name set to "{new}".',
        card_number: 'Card number set to "{new}".',
        account_number: 'Account number set to "{new}".',
        shaba_number: 'Shaba number set to "{new}".'
      },

      cash: {
        name: 'Name set to "{new}".'
      },

      payment_gateway: {
        type: 'Type of payment gateway set to "{new}"',
        title: 'Title set to "{new}"',
        description: 'Description set to "{new}"',
        bank: 'Receiving bank set to "{new}"',
        enabled: 'Status of payment gateway set to "{new}"',
        end_time: 'Working day end time set to "{new}"',
        merchant_code: 'Merchant set to "{new}"',
        terminal_code: 'Terminal set to "{new}"'
      },

      cost: {
        payer: 'Payer set to "{new}".',
        account: 'Account set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        date: 'Date set to "{new}".'
      },

      internal_fund: {
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        type: 'Type set to "{new}".',
        price: 'Price set to "{new}".',
        wage: 'Wage set to "{new}".',
        reference: 'Reference id set to "{new}".',
        date: 'Date set to "{new}".'
      },

      payment: {
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        type: 'Type set to "{new}".',
        date: 'Date set to "{new}".'
      },

      receive: {
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        status: 'Status set to "{new}".',
        reference: 'Reference id set to "{new}".',
        date: 'Date set to "{new}".'
      },

      role: {
        name: 'Name set to "{new}".',
        parent: 'Parent set to "{new}".',
        allowed_active_sessions: 'Maximum active sessions set to "{new}".',
        permissions: {
          id: 'Permission id set to "{new}".',
          name: 'Permission name set to "{new}".',
          slug: 'Permission slug set to "{new}".'
        }
      },

      invoice: {
        user: 'User set to "{new}".',
        invoice_number: 'Invocie number set to "{new}".',
        type: 'Type set to "{new}".',
        action_type: 'Action type set to "{new}".',
        status: 'Status set to "{new}".',
        invoice_date: 'Invoice date set to "{new}".',
        details: 'Invoice details set to "{new}".',
        note: 'Note set to "{new}".',
        shipping: 'Shipping price set to "{new}".',
        final_price: 'Final price set to "{new}".',
        address: {
          province: 'Province set to "{new}".',
          city: 'City set to "{new}".',
          address: 'Address set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".'
        },
        lines: {
          product_variant: 'Product variant set to "{new}".',
          storeroom: 'Product storeroom set to "{new}".',
          unit_price: 'product unit price set to "{new}".',
          quantity: 'Product quantity set to "{new}".',
          discount: 'Product discount set to "{new}".',
          tax: 'Product vat set to "{new}".'
        },
        services: {
          service: 'Service discount set to "{new}".',
          quantity: 'Service quantity set to "{new}".',
          required: 'Service required set to "{new}".',
          unit_price: 'Service unit price set to "{new}".',
          discount: 'Service discount set to "{new}".',
          cancel_price: 'Service cancel price set to "{new}".',
          vat: 'Service vat set to "{new}".'
        }
      }
    },

    update: {
      user: {
        name: 'Name updated from "{old}" to "{new}".',
        family: 'Family updated from "{old}" to "{new}".',
        gender: 'Gender updated from "{old}" to "{new}".',
        avatar: 'User Avatar updated',
        company: 'Company updated from "{old}" to "{new}".',
        phone_number: 'Phone number updated from "{old}" to "{new}".',
        email: 'Email updated from "{old}" to "{new}".',
        max_debt: 'Max debt updated from "{old}" to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          province: 'Province updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          title: 'Title updated from "{old}" to "{new}".'
        }
      },

      club: {
        avatar: 'Club logo updated',
        name: 'Name updated from "{old}" to "{new}".',
        sport: 'Sport updated from "{old}" to "{new}".',
        manager: 'Manager updated from "{old}" to "{new}".',
        sale_plan: 'Sale plan updated from "{old}" to "{new}".',
        city: 'City updated from "{old}" to "{new}".',
        province: 'Province updated from "{old}" to "{new}".',
        address: 'Address updated from "{old}" to "{new}".',
        support_number: 'Support number updated from "{old}" to "{new}".',
        email: 'Email updated from "{old}" to "{new}".',
        subdomain: 'Subdomain updated from "{old}" to "{new}".',
        club_locale: 'Locale updated from "{old}" to "{new}".',
        enable_online_booking: 'Enable online booking updated from "{old}" to "{new}".',
        score: 'Score updated from "{old}" to "{new}".',
        images: {
          path: 'Club image updated'
        }
      },

      sale_plan: {
        avatar: 'Sale plan logo updated',
        name: 'Name updated from "{old}" to "{new}".',
        code: 'Code updated from "{old}" to "{new}".',
        public: 'Public status updated from "{old}" to "{new}".',
        period: 'Plan period updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        sale_price: 'Sale price updated from "{old}" to "{new}".',
        re_purchase: 'Re-purchase status updated from "{old}" to "{new}".'
      },

      sport: {
        logo: 'Sport logo updated',
        name: 'Name updated from "{old}" to "{new}".',
        slug: 'Slug updated from "{old}" to "{new}".'
      },

      bank: {
        name: 'Name updated from "{old}" to "{new}".',
        card_number: 'Card number updated from "{old}" to "{new}".',
        account_number: 'Account number updated from "{old}" to "{new}".',
        shaba_number: 'Shaba number updated from "{old}" to "{new}".'
      },

      cash: {
        name: 'Name updated from "{old}" to "{new}".'
      },

      payment_gateway: {
        type: 'Type of payment gateway updated',
        title: 'Title updated from "{old}" to "{new}"',
        description: 'Description updated from "{old}" to "{new}"',
        bank: 'Receiving bank updated from "{old}" to "{new}"',
        enabled: 'Status of payment gateway updated',
        end_time: 'Working day end time updated from "{old}" to "{new}"',
        merchant_code: 'Merchant updated from "{old}" to "{new}"',
        terminal_code: 'Terminal updated from "{old}" to "{new}"'
      },

      cost: {
        payer: 'Payer updated from "{old}" to "{new}".',
        account: 'Account updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      internal_fund: {
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        wage: 'Wage updated from "{old}" to "{new}".',
        reference: 'Reference id updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      payment: {
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      receive: {
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        reference: 'Reference id updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      role: {
        name: 'Name updated from "{old}" to "{new}".',
        parent: 'Parent updated from "{old}" to "{new}".',
        allowed_active_sessions: 'Maximum active sessions updated from "{old}" to "{new}".',
        permissions: {
          id: 'Permission id updated from "{old}" to "{new}".',
          name: 'Permission name updated from "{old}" to "{new}".',
          slug: 'Permission slug updated from "{old}" to "{new}".'
        }
      },

      invoice: {
        user: 'User updated from "{old}" to "{new}".',
        invoice_number: 'Invocie number updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        action_type: 'Action type updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        invoice_date: 'Invoice date updated from "{old}" to "{new}".',
        details: 'Invoice details updated from "{old}" to "{new}".',
        note: 'Note updated from "{old}" to "{new}".',
        shipping: 'Shipping price updated from "{old}" to "{new}".',
        final_price: 'Final price updated from "{old}" to "{new}".',
        address: {
          province: 'Province updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          address: 'Address updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".'
        },
        lines: {
          product_variant: 'Product variant updated from "{old}" to "{new}".',
          storeroom: 'Product storeroom updated from "{old}" to "{new}".',
          unit_price: 'product unit price updated from "{old}" to "{new}".',
          quantity: 'Product quantity updated from "{old}" to "{new}".',
          discount: 'Product discount updated from "{old}" to "{new}".',
          tax: 'Product vat updated from "{old}" to "{new}".'
        },
        services: {
          service: 'Service discount updated from "{old}" to "{new}".',
          quantity: 'Service quantity updated from "{old}" to "{new}".',
          required: 'Service required updated from "{old}" to "{new}".',
          unit_price: 'Service unit price updated from "{old}" to "{new}".',
          discount: 'Service discount updated from "{old}" to "{new}".',
          cancel_price: 'Service cancel price updated from "{old}" to "{new}".',
          vat: 'Service vat updated from "{old}" to "{new}".'
        }
      }
    }
  }
}
