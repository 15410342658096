export default {
  title: 'Clubs',

  labels: {
    images: 'Images',
    name: 'Name',
    subdomain: 'Subdomain',
    manager: 'Manager',
    sport: 'Sport',
    country: 'Country',
    province: 'Province',
    city: 'City',
    address: 'Address',
    supportNumber: 'Support number',
    email: 'Email',
    delete: 'Delete'
  },

  table: {
    header: {
      row: 'Row',
      logo: 'Logo',
      name: 'Name',
      manager: 'Manager',
      city: 'City',
      province: 'Province',
      country: 'Country',
      supportNumber: 'Support Number',
      email: 'Email',
      status: 'Status',
      delete: 'Delete',
      restore: 'Restore'
    }
  },

  notifications: {
    wrongInputValue: 'Some input has wrong value',

    insert: {
      success: 'Club insert successfully',
      error: 'Insert club has error'
    },

    edit: {
      success: 'Club update successfully',
      error: 'Edit club has error'
    },

    deploy: {
      error: 'Club deploy has error'
    },

    delete: {
      success: 'Club removed successfully',
      error: 'Delete club has error'
    },

    restore: {
      success: 'Club restored successfully',
      error: 'Restore club has error'
    },

    parseError: {
      name: 'Name is not valid or exist',
      subdomain: 'Subdomain is not valid or exist',
      sport: 'Sport is not valid',
      supportNumber: 'Support number is not valid or exist',
    }
  },

  validators: {
    subdomain: 'Subdomain only accept English character',
    subdomainNumber: 'Subdomain cant start with digits',
    subdomainExist: 'Subdomain is used',
    illegalSubdomain: 'Subdomain is illegal or used'
  },

  confirmations: {
    delete: {
      title: 'Delete club confirmation',
      body: 'Are you sure about delete this club?'
    },

    deploy: {
      title: 'Deploy club confirmation',
      body: 'Are you sure about deploy booking system of this club?'
    }
  },

  statusTypes: {
    all: 'All',
    '0': 'Signup',
    '1': 'Confirm info',
    '2': 'Active',
    '3': 'Disactive',
    '4': 'Need to pay'
  },

  list: {
    title: 'Clubs list'
  },

  trash: {
    title: 'Removed clubs list'
  },

  insert: {
    title: 'Insert new club'
  },

  edit: {
    title: 'Edit club',
    dynamicTitle: 'Edit {name}'
  },

  profile: {
    title: 'Club profile',
    dynamicTitle: '{name} profile',

    importantData: {
      score: 'Point',
      subdomain: 'Subdomain'
    },

    actions: {
      clubCalendar: 'Calendar',
      clubRequests: 'Requests',
      clubReceives: 'Receives',
      clubDeploy: 'Deploy',
      clubLogin: 'Login to panel',
      logs: 'Activity logs'
    }
  },

  calendar: {
    title: 'Club calendar',

    labels: {
      lastWeek: 'Last week',
      futureWeek: 'Future week',
      timeCourt: 'Time / Court',
      confirm: 'Confirm reserve list',
    }
  },

  deploy: {
    title: 'Deploy club',
    dynamicTitle: 'Deploy {name}',

    description: 'For deploy club, click on the Deploy button',

    steps: {
      request_created: 'Request created',
      subdomain_created: 'Subdomain created',
      database_created: 'Database created',
      environment_created: 'Environment created',
      deployed: 'club deployed'
    },

    notifications: {
      success: {
        title: 'The booking system of {name} club has been successfully launched',
        body: 'To view the management panel of this club, click on the button below'
      },
      parseError: {
        hasDeployed: 'This club app was deployed'
      }
    },

    actions: {
      deploy: 'Deploy',
      goToClub: 'Login to panel'
    }
  },

  requests: {
    title: 'Clubs requests',

    labels: {
      department: 'Department',
      type: 'Type',
      status: 'Status',
      details: 'Details',
      answer: 'Answer',
      reject: 'Reject',
      accept: 'Accept'
    },

    table: {
      header: {
        row: 'Row',
        date: 'Date',
        department: 'Department',
        details: 'Details',
        type: 'Type',
        status: 'Status'
      }
    },

    departmentOptions: {
      all: 'All',
      1: 'Technical',
      2: 'Financial'
    },

    typeOptions: {
      all: 'All',
      1: 'Update club info'
    },

    statusOptions: {
      all: 'All',
      0: 'Created',
      1: 'Pending',
      2: 'Accepted',
      3: 'Rejected'
    },

    notifications: {
      emptyAnswer: 'No answer entered for the request',

      reject: {
        success: 'Club request successfully rejected',
        error: 'Reject club request has error'
      },

      confirm: {
        success: 'Club request successfully accepted',
        error: 'Accept club request has error'
      }
    },

    list: {
      title: 'Club requests list'
    },

    profile: {
      title: 'Club request'
    },

    clubInfo: {
      title: 'Club info',

      labels: {
        generalInfo: 'General info',
        name: 'Name',
        locale: 'Locale',
        onlineBooking: 'Enable online booking status',
        telInfo: 'Tel info',
        province: 'Province',
        city: 'City',
        address: 'Address',
        supportNumber: 'Support number',
        email: 'Email'
      }
    }
  },

  receives: {
    title: 'Club receives',

    list: {
      title: 'Club receives',
    }
  },

  logs: {
    title: 'Club Logs'
  }
}
