export default {
  title: 'Sales',

  proformaInvoices: {
    title: 'Pre-invoice'

  },

  invoices: {
    title: 'Sales',

    labels: {
      id: 'Invoice ID',
      user: 'Customer',
      fullName: 'Full Name',
      address: 'Address',
      registryDate: 'Registration Date',
      orderDate: 'Order Date',
      phoneNumber: 'Phone number',
      pre: 'pre',
      status: 'Status',
      store: 'store',
      note: 'note',
      availableProduct: 'Available Products',
      allProduct: 'All Products',

      productPrice: 'Products price',
      servicePrice: 'Services price',
      tax: 'Vat',
      totalDiscount: 'Total discount',
      totalPrice: 'Total price',
      finalPrice: 'Final price',
      shipping: 'Shipping',
      required: '(required)',
      TRN: 'TRN: ',
      services: {
        name: 'Service name',
        quantity: 'Quantity',
        price: 'Price'
      }
    },

    placeholders: {
      id: 'Automatic'
    },

    prompts: {
      receive: {
        title: 'Receive money from the customer',
        dynamicTitle: 'Receive funds from {name}',

        subject: 'Sales invoice number {id}',

        actions: {
          payment: 'Pay',
          cancel: 'Cancel and view pre-invoice'
        }
      },

      confirm: {
        title: 'Create sale invoice confirmation',

        actions: {
          payment: 'Confirm',
          cancel: 'Cancel'
        }
      },

      address: {
        insert: {
          title: '',
          dynamicTitle: 'Add new address for {name}'
        },

        list: {
          title: '',
          dynamicTitle: 'Address List {name}'
        }
      },

      users: {
        list: {
          title: 'User List'
        },
        insert: {
          title: 'Add new user'
        }
      },

      noteDialog: {
        title: 'Customer Invoice Note'
      }
    },

    actionTypes: {
      present: 'In-person sales invoice',
      tel: 'Telephone sales invoice',
      internet: 'Internet sales invoice'
    },

    processTypes: {
      proformaInvoices: 'sales pre-invoice',
      invoices: 'sales invoice',
      storeExit: 'Exit Warehouse'
    },

    notifications: {
      isExists: {
        message: 'This value is in the list',
        dynamicMessage: 'There are {name} in the list'
      },

      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of { names } added'
      },

      productNotFount: 'No product found with this barcode',
      quantity: 'Inventory is not enough!',
      dynamicQuantity: 'There is not enough stock of {name}!',
      lowBalance: 'Pre-invoice was registered, but due to lack of customer credit, it is necessary to receive money to complete the invoice!',
      discountIsBigger: 'The discount of {name} is more than the total price of the product!',

      insert: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration was incorrect!'
      },

      edit: {
        success: 'Invoice edited successfully',
        error: 'Invoice editing encountered an error!'
      },

      delete: {
        success: 'Invoice successfully deleted',
        error: 'Deleting the sales invoice was accompanied by an error'
      },

      restore: {
        success: 'Invoice successfully restored',
        error: 'Restore sales invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Invoice Status Returned Successfully',
        error: 'Return invoice status has error'
      },

      parseError: {
        user: 'Customer not selected!',
        accountSide: 'Account side not selected!',
        lines: 'No items selected!',
        address: 'The address was not entered correctly!',
        storeroom: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!',
        discount: 'The entered discount is not valid!',
        dynamicDiscount: 'The entered discount in row {index} is not valid!',
        noProductEnter: 'No Product Selected!'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of sales invoice',
        body: 'If the sales invoice is deleted, all the documents and remittances of the warehouse related to the invoice will be deleted. Are you sure? '
      },
      forceDelete: {
        title: 'Confirmation of deletion of sales invoice',
        body: 'If the sales invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      },
      preInvoiceWithoutQuantity: {
        title: 'Pre-sale invoice confirmation',
        body: 'Some of the selected products are out of stock. Are you sure of confirming the invoice as a pre-invoice?'
      },
      forceUpdate: {
        title: 'Sale invoice edit confirmation',
        body: 'By edit sale invoice . Are you sure of confirming edit sale invoice?'
      }
    },

    list: {
      title: 'Sales List',

      table: {
        header: {
          id: 'Invoice number',
          receivedId: 'Reference invoice number',
          creator: 'Creator',
          user: 'Customer',
          province: 'Province',
          date: 'Date',
          finalPrice: 'Final amount',
          type: 'Type of sale',
          status: 'Status',
          restore: 'Restore',
          delete: 'Delete'
        },

        filters: {
          type: {
            all: 'All',
            present: 'Presence Sales',
            tel: 'Telephone sales',
            internet: 'Internet sales'
          },
          status: {
            all: 'All',
            proformaInvoices: 'sales pre-invoice',
            invoices: 'sales invoice',
            storeExit: 'Exit Warehouse'
          }
        }
      },

      actionTypes: {
        present: 'Presence Sales',
        tel: 'Telephone sales',
        internet: 'Internet sales'
      }
    },

    insert: {
      title: 'Add sales invoice',

      telTypeTitle: 'Add Telephone Invoice',
      presentTypeTitle: 'Add In-Face Sales Invoice',

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Total discount',
          finalPrice: 'Final amount',
          storeroom: 'Store',
          delete: 'Delete'
        }
      }
    },

    invoice: {
      title: 'Sales Invoice',
      dynamicTitle: 'Sales {pre} Invoice {type} No. {id}',
      dynamicTitleWithoutId: 'Sales Invoice {type}',

      labels: {
        payment: 'payment',
        cancel: 'Cancel'
      },

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Barcode',
          name: 'Product / Service',
          variant: 'Variable',
          store: 'Store',
          count: 'QTY',
          tax: 'Vat',
          unitPrice: 'Unit price',
          totalPrice: 'Total',
          discount: 'Discount',
          finalPrice: 'Final amount'
        }
      },

      validators: {
        user: 'Supplier not selected!',
        products: 'No product selected!',
        store: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'Inventory is not enough!',
        lowPrice: 'Pre-invoice was registered, but due to lack of customer credit, you need to receive money to complete the invoice!'
      },

      notifications: {
        complete: {
          success: 'Invoice registered successfully',
          error: 'Invoice registration was incorrect!'
        },

        storeExit: {
          error: 'Sales invoice has not been registered'
        }
      },

      actionTypes: {
        present: 'In shop',
        tel: 'Telephone',
        internet: 'Internet'
      },

      actions: {
        print: 'Print',
        back: 'Back'
      },

      logs: {
        title: 'Sale invoice Logs',
      },
    },

    edit: {
      title: 'Edit Sales Invoice',
      dynamicTitle: 'Edit Sales Invoice {type}',

      labels: {
        first: 'first',
        second: 'second'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          store: 'Store',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Discount',
          finalPrice: 'Final amount',
          delete: 'Delete'
        }
      },

      actionTypes: {
        present: 'In shop',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    trash: {
      title: 'Sale invoice trash list'
    },

    refreshStatus: {
      title: 'Return the sales invoice status',

      description: 'Keep in mind that if the invoice status is returned, all documents and remittances related to each status will be deleted',

      confirm: 'Confirm',
      cancel: 'Cancel'
    }
  },

  returnInvoices: {
    title: 'Order Refunds',

    labels: {
      user: 'Customer',
      id: 'Invoice ID',
      date: 'Registration date',
      status: 'Status'
    },

    placeholders: {
      id: 'Automatic'
    },

    status: {
      all: 'All',
      preInvoice: 'pre-invoice sales reference',
      invoice: 'sales reference invoice',
      storeReceived: 'Warehouse Receipt'
    },

    actionTypes: {
      present: 'face-to-face reference',
      tel: 'Telephone sales reference',
      internet: 'Internet sales reference'
    },

    notifications: {
      document: {
        success: 'Invoice document successfully registered',
        error: 'The registration of the invoice document was accompanied by an error',
        isExist: 'Invoice document registered'
      },

      insert: {
        success: 'Sales return invoice successfully registered',
        error: 'Registration of sales return invoice was accompanied by an error!'
      },

      edit: {
        success: 'Sales return invoice successfully edited',
        error: 'Editing the return sales invoice was accompanied by an error!'
      },

      delete: {
        success: 'Sale invoice successfully deleted',
        error: 'Delete sale invoice has error'
      },

      restore: {
        success: 'Invoice successfully restored',
        error: 'Restore  return sales invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Invoice status returned successfully',
        error: 'Invert invoice status returned failed'
      },

      parseError: {
        user: 'Supplier not selected!',
        lines: 'No items selected!',
        store: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        count: 'The number of selected products is not valid!',
        quantity: 'Inventory is not enough!',
        dynamicQuantity: 'There is not enough stock of {name}!'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation delete the returned sales invoice',
        body: 'In case of deleting the returned sales invoice, all documents and warehouse transfers related to the invoice will be deleted. are you sure?'
      },
      forceDelete: {
        title: 'Confirmation of deletion return sales invoice',
        body: 'If the return sales invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      },
    },

    list: {
      title: 'Order Refunds',

      table: {
        header: {
          id: 'Invoice number',
          receivedId: 'Reference invoice number',
          creator: 'Creator',
          user: 'Customer',
          province: 'Province',
          date: 'Date',
          totalPrice: 'Total amount',
          finalPrice: 'Final amount',
          type: 'Type of sale',
          status: 'Status',
          restore: 'Restore',
          delete: 'Delete'
        },

        filters: {
          type: {
            all: 'All',
            present: 'Presence Sales',
            tel: 'Telephone sales',
            internet: 'Internet sales'
          }
        }
      }
    },

    insert: {
      title: 'Create New Order Refunds',

      typeTitle: {
        present: 'Add in-person sales reference invoice',
        tel: 'Add telephone sales reference invoice',
        internet: 'Add internet sales reference invoice'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          count: 'Count',
          tax: 'Vat',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Total discount',
          finalPrice: 'Final amount',
          store: 'Store',
          delete: 'Delete'
        }
      }
    },

    invoice: {
      title: 'Sales Reference Invoice',

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          tax: 'Vat',
          discount: 'Total discount',
          store: 'Store',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount'
        }
      },

      logs: {
        title: 'Sale Reference Invoice Logs',
      },
    },

    edit: {
      title: 'Edit sales reference invoice',

      typeTitle: {
        present: 'Edit face-to-face sales reference invoice',
        tel: 'Edit telephone sales reference invoice',
        internet: 'Edit internet sales reference invoice'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          count: 'Count',
          tax: 'Vat',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Total discount',
          finalPrice: 'Final amount',
          store: 'Store',
          delete: 'Delete'
        }
      }
    },

    trash: {
      title: 'Return sale invoice trash'
    },

    refreshStatus: {
      title: 'Return sales invoice status',

      labels: {
        description: 'Note that if the invoice status is returned, all documents and remittances related to each status will be deleted',
        accept: 'Confirm',
        cancel: 'Cancel'
      }
    }
  },

  serviceInvoices: {
    title: 'Service invoices',

    labels: {
      id: 'Id',
      user: 'Customer',
      address: 'Address',
      registryDate: 'Registry Date',
      status: 'Status',
      store: 'Warehouse'
    },

    placeholders: {
      id: 'Automatic'
    },

    prompts: {
      receive: {
        title: 'Receive money from the customer',
        dynamicTitle: 'Receive funds from {name}',

        subject: 'Services sale invoice number {id}',

        actions: {
          payment: 'Pay',
          cancel: 'Cancel and view pre-invoice'
        }
      },

      address: {
        insert: {
          title: '',
          dynamicTitle: 'Add new address for {name}'
        },

        list: {
          title: '',
          dynamicTitle: 'Address List {name}'
        }
      },

      users: {
        list: {
          title: 'User List'
        },
        insert: {
          title: 'Add new user'
        }
      }
    },

    actionTypes: {
      present: 'In-person service invoice',
      tel: 'Telephone service invoice',
      internet: 'Internet service invoice'
    },

    processTypes: {
      proformaInvoices: 'service pre-invoice',
      invoices: 'service invoice',
      storeExit: 'Exit Warehouse'
    },

    notifications: {
      isExists: {
        message: 'This value is in the list',
        dynamicMessage: 'There are {name} in the list'
      },

      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of { names } added'
      },

      productNotFount: 'No product found with this barcode',
      quantity: 'Inventory is not enough!',
      dynamicQuantity: 'There is not enough stock of {name}!',
      lowBalance: 'Pre-invoice was registered, but due to lack of customer credit, it is necessary to receive money to complete the invoice!',
      discountIsBigger: 'The discount of {name} is more than the total price of the service!',

      insert: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration was incorrect!'
      },

      edit: {
        success: 'Invoice edited successfully',
        error: 'Invoice editing encountered an error!'
      },

      delete: {
        success: 'Invoice successfully deleted',
        error: 'Deleting the sales invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Invoice Status Returned Successfully',
        error: 'Return invoice status has error'
      },


      parseError: {
        user: 'Supplier not selected!',
        accountSide: 'Account side not selected!',
        lines: 'No items selected!',
        address: 'The address was not entered correctly!',
        storeroom: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!',
        discount: 'The entered discount is not valid!'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of service invoice',
        body: 'If the service invoice is deleted, all the documents and remittances of the warehouse related to the invoice will be deleted. Are you sure? '
      }
    },

    list: {
      title: 'Service invoices',

      table: {
        header: {
          id: 'Invoice number',
          receivedId: 'Reference invoice number',
          creator: 'Creator',
          user: 'Customer',
          date: 'Date',
          finalPrice: 'Final amount',
          type: 'Type',
          status: 'Status'
        },

        filters: {
          type: {
            all: 'All',
            present: 'Presence Sales',
            tel: 'Telephone sales',
            internet: 'Internet sales'
          },
          status: {
            all: 'All',
            proformaInvoices: 'service pre-invoice',
            invoices: 'service invoice',
            storeExit: 'Exit Warehouse'
          }
        }
      },

      actionTypes: {
        present: 'Presence service',
        tel: 'Telephone service',
        internet: 'Internet service'
      }
    },

    insert: {
      title: 'Add service invoice',

      telTypeTitle: 'Add Telephone Invoice',
      presentTypeTitle: 'Add In-Face Service Invoice',

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Service',
          variant: 'Variable',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Total discount',
          finalPrice: 'Final amount',
          storeroom: 'Store',
          delete: 'Delete'
        }
      }
    },

    invoice: {
      title: 'Service invoice',
      dynamicTitle: 'Service Invoice {type} No. {id}',
      dynamicTitleWithoutId: 'Service Invoice {type}',

      labels: {
        payment: 'Pay',
        cancel: 'Cancel'
      },

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Barcode',
          name: 'Service',
          count: 'Quantity',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Discount',
          finalPrice: 'Final amount'
        }
      },

      validators: {
        user: 'Supplier not selected!',
        products: 'No Service selected!',
        store: 'Warehouse not selected!',
        unitPrice: 'The amount of the service has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'Inventory is not enough!',
        lowPrice: 'Pre-invoice was registered, but due to lack of customer credit, you need to receive money to complete the invoice!'
      },

      notifications: {
        complete: {
          success: 'Invoice registered successfully',
          error: 'Invoice registration was incorrect!'
        },

        storeExit: {
          error: 'Service invoice has not been registered'
        }
      },

      actionTypes: {
        present: 'In shop',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    edit: {
      title: 'Edit Service Invoice',
      dynamicTitle: 'Edit Service Invoice {type}',

      labels: {
        first: 'First',
        second: 'Second'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Service',
          variant: 'Variable',
          store: 'Store',
          count: 'Count',
          unitPrice: 'Unit amount',
          totalPrice: 'Total amount',
          discount: 'Discount',
          finalPrice: 'Final amount',
          delete: 'Delete'
        }
      },

      actionTypes: {
        present: 'In shop',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    refreshStatus: {
      title: 'Return the Service invoice status',

      description: 'Keep in mind that if the invoice status is returned, all documents and remittances related to each status will be deleted',

      statusTypes: {
        preInvoice: 'Service pre-invoice',
        invoice: 'Service invoice'
      },

      confirm: 'Confirm',
      cancel: 'Cancel'
    }
  },

  groups: {
    title: 'Sales Groups',

    list: {
      title: 'List of sales groups'
    },

    insert: {
      title: 'Add new sales group'
    },

    edit: {
      title: 'Edit Sales Group'
    }
  },

  pricing: {
    title: 'Pricing',

    table: {

      header: {
        row: 'Row',
        code: 'Code',
        name: 'Name',
        dynamic: 'Variable',
        amount: 'Mission',
        date: 'Registration date',
        action: 'Operation'
      },

      content: {

        button: {
          set: 'Register'
        }
      }
    }
  },

  discount: {
    title: 'Sales Discounts'

  },

  actions: {
    insert: 'Add',
    edit: 'edit',
    filters: 'Filters'
  }
}
