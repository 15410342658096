export default {
  title: 'Booking',

  salePlan: {
    title: 'Sale plans',

    labels: {
      code: 'Code',
      name: 'Name',
      period: 'Period time',
      price: 'Price',
      salePrice: 'Sale price',
      public: 'Plan is public',
      rePurchase: 'Plan is auto re-purchase',
      delete: 'Delete'
    },

    table: {
      header: {
        row: 'Row',
        code: 'Code',
        name: 'Name',
        period: 'Period',
        price: 'Price',
        salePrice: 'Sale price',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    notifications: {
      wrongInputValue: 'Input value is wrong',
      insert: {
        success: 'Sale plan successfully created',
        error: 'Insert sale plan has error'
      },
      edit: {
        success: 'Sale plan successfully updated',
        error: 'Update sale plan has error'
      },
      delete: {
        success: 'Sale plan successfully removed',
        error: 'Delete sale plan has error'
      },
      restore: {
        success: 'Sale plan successfully restored',
        error: 'Restore sale plan has error'
      },
      editLimits: {
        success: 'Sale plan limits successfully updated',
        error: 'Update sale plan limits has error'
      },
      parseError: {
        name: 'Name is not valid',
        code: 'Code is not valid or is used to another plan'
      }
    },

    validators: {
      permissions: 'No permission set for this plan'
    },

    confirmations: {
      delete: {
        title: 'Delete sale plan confirmation',
        body: 'Are you sure about delete this sale plan?'
      }
    },

    list: {
      title: 'Sale plans'
    },

    trash: {
      title: 'Removed sale plans'
    },

    insert: {
      title: 'Insert new sale plan'
    },

    edit: {
      title: 'Edit sale plan',
      dynamicTitle: 'Edit {name} plan'
    },

    profile: {
      title: 'Sale plan profile',
      dynamicTitle: '{name} plan profile',

      importantData: {
        code: 'Code',
        price: 'Price'
      },

      actions: {
        clubs: 'Clubs',
        limits: 'Limits',
        logs: 'Activity logs'
      }
    },

    clubs: {
      title: 'Sale plan clubs list'
    },

    limits: {
      title: 'Sale plan limits',

      labels: {
        inMenu: 'Menu'
      }
    },

    logs: {
      title: 'Sale plan Logs'
    }
  },

  sports: {
    title: 'Sports',

    labels: {
      name: 'Name',
      slug: 'Slug',
      delete: 'Delete'
    },

    table: {
      header: {
        select: 'Select',
        row: 'Row',
        logo: 'Logo',
        name: 'Name',
        slug: 'Slug',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    notifications: {
      wrongInputValue: 'Input value is wrong',
      sportUsed: 'Sport is used in system and cant delete',
      insert: {
        success: 'Sport successfully created',
        error: 'Insert sport has error'
      },
      edit: {
        success: 'Sport successfully updated',
        error: 'Update sport has error'
      },
      delete: {
        success: 'Sport successfully removed',
        error: 'Delete sport has error'
      },
      restore: {
        success: 'Sport successfully restored',
        error: 'Restore sport has error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete sport confirmation',
        body: 'Are you sure about delete this sport?'
      }
    },

    validators: {
      slug: 'Slug only accept English character',
      slugNumber: 'Slug cant start with digits'
    },

    list: {
      title: 'Sports list'
    },

    select: {
      title: 'Sports list'
    },

    trash: {
      title: 'Sports removed list'
    },

    insert: {
      title: 'Insert new sport'
    },

    edit: {
      title: 'Edit sport'
    },

    profile: {
      title: 'Sport profile',
      dynamicTitle: '{name} profile',

      importantData: {
        slug: 'Slug'
      },

      actions: {
        clubs: 'Clubs',
        logs: 'Activity logs'
      }
    },

    clubs: {
      title: 'Sport clubs list'
    },

    logs: {
      title: 'Sport Logs'
    }
  }
}