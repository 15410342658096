export default {
  subject: {
    // delete old pre-invoices
    delete_old_sale_invoices: 'Old pre sale invoices deleted',
    delete_old_purchase_invoices: 'Old pre purchase invoices deleted',
    delete_old_return_sale_invoices: 'Old pre return sale invoices deleted',
    delete_old_return_purchase_invoices: 'Old pre return purchase invoices deleted',
    delete_old_sale_invoices_dynamic: '{count} old pre sale invoices deleted',
    delete_old_purchase_invoices_dynamic: '{count} old pre purchase invoices deleted',
    delete_old_return_sale_invoices_dynamic: '{count} old pre return sale invoices deleted',
    delete_old_return_purchase_invoices_dynamic: '{count} old pre return purchase invoices deleted',

    invoices: {
      woo_controller_invoice_technical_problem: 'Invoice has a technical problem',
      woo_controller_invoice_technical_problem_dynamic: 'Invoice with id {id} has a technical problem',
      woo_controller_invoice_transferred: 'Invoice information has been successfully transferred to Useral',
      woo_controller_invoice_updated: 'Invoice information has been successfully updated in Useral',

      create_insert_order_webhook_succeed: 'Create insert order webhook succeed',
      create_insert_order_webhook_failed: 'Create insert order webhook failed',

      create_update_order_webhook_succeed: 'Create update order webhook succeed',
      create_update_order_webhook_failed: 'Create update order webhook failed',

      update_woo_controller_order_status_in_shop_succeed: 'Update order status in shop succeed',
      update_woo_controller_order_status_in_shop_failed: 'Update order status in shop failed'
    },
    users: {
      woo_controller_user_technical_problem: 'User technical problem',
      woo_controller_user_technical_problem_dynamic: 'User with id {id} has technical problem',

      delete_woo_controller_user_from_shop_succeed: 'Delete user from shop succeed',
      delete_woo_controller_user_from_shop_failed: 'Delete user from shop failed',

      create_woo_controller_user_in_shop_success: 'Create user in shop success',
      create_woo_controller_user_in_shop_failed: 'Create user in shop failed',

      update_woo_controller_user_in_shop_success: 'Update user in shop success',
      update_woo_controller_user_in_shop_failed: 'Update user in shop failed',

      delete_woo_controller_user_in_shop_success: 'Delete user in shop success',
      delete_woo_controller_user_in_shop_failed: 'Delete user in shop failed',

      update_woo_controller_user_in_shop_succeed: 'Update user in shop succeed',

      create_insert_customer_webhook_succeed: 'Create insert customer webhook succeed',
      create_insert_customer_webhook_failed: 'Create insert customer webhook failed',

      create_update_customer_webhook_succeed: 'Create update customer webhook succeed',
      create_update_customer_webhook_failed: 'Create update customer webhook failed',

      create_delete_customer_webhook_succeed: 'Create delete customer webhook succeed',
      create_delete_customer_webhook_failed: 'Create delete customer webhook failed'
    },
    products: {
      woo_controller_product_variant_technical_problem: 'Product variant technical problem',
      woo_controller_product_technical_problem: 'Product technical problem',

      woo_controller_product_variant_technical_problem_dynamic: 'Product variant with barcode {barcode} technical problem',
      woo_controller_product_technical_problem_dynamic: 'Product with barcode {barcode} technical problem',

      delete_woo_controller_product_from_shop_failed: 'Delete product from shop failed',
      delete_woo_controller_product_variant_from_shop_failed: 'Delete product variant from shop failed',

      restore_woo_controller_product_in_shop_success: 'Restore product in shop success',
      restore_woo_controller_product_in_shop_failed: 'Restore product in shop failed',

      create_woo_controller_product_in_shop_success: 'Create product in shop success',
      create_woo_controller_product_in_shop_failed: 'Create product in shop failed',

      update_woo_controller_product_in_shop_success: 'Update product in shop success',
      update_woo_controller_product_in_shop_failed: 'Update product in shop failed',

      create_woo_controller_product_variant_in_shop_success: 'Create product variant in shop success',
      create_woo_controller_product_variant_in_shop_failed: 'Create product variant in shop failed',

      update_woo_controller_product_variant_in_shop_success: 'Update product variant in shop success',
      update_woo_controller_product_variant_in_shop_failed: 'Update product variant in shop failed',

      woo_controller_product_updated_in_useral: 'Product updated in useral',
      woo_controller_product_variant_updated_in_useral: 'Product variant updated in useral',

      woo_controller_product_variant_transferred: 'Product variant transferred',

      deleted_woo_controller_product_variant_from_shop: 'Product variant from shop deleted',

      create_insert_product_webhook_succeed: 'Create insert product webhook succeed',
      create_insert_product_webhook_failed: 'Create insert product webhook failed',

      create_update_product_webhook_succeed: 'Create update product webhook succeed',
      create_update_product_webhook_failed: 'Create update product webhook failed',

      create_delete_product_webhook_succeed: 'Create delete product webhook succeed',
      create_delete_product_webhook_failed: 'Create delete product webhook failed',

      update_woo_controller_product_stock_in_shop_succeed: 'Update product stock in shop succeed',
      update_woo_controller_product_stock_in_shop_failed: 'Update product stock in shop failed',

      update_woo_controller_product_variant_stock_in_shop_succeed: 'Update product variant stock in shop succeed',
      update_woo_controller_product_variant_stock_in_shop_failed: 'Update product variant stock in shop failed',

      update_woo_controller_product_variant_price_in_shop_succeed: 'Update product variant price in shop succeed',
      update_woo_controller_product_variant_price_in_shop_failed: 'Update product variant price in shop failed',

      woo_controller_product_price_updated_from_shop: 'Product price updated from shop',
      woo_controller_product_stock_updated_from_shop: 'Product stock updated from shop',
      woo_controller_product_variant_stock_updated_from_shop: 'Product variant stock updated from shop',

      update_woo_controller_product_price_in_shop_succeed: 'Update product price in shop succeed',
      update_woo_controller_product_price_in_shop_failed: 'Update product price in shop failed',
      deleted_woo_controller_product_from_shop: 'Deleted product from shop',
      woo_controller_product_transferred: 'Product transferred'
    },
    attributes: {
      create_woo_controller_attribute_value_in_shop_succeed: 'Create attribute value in shop succeed',
      create_woo_controller_attribute_value_in_shop_failed: 'Create attribute value in shop failed',

      update_woo_controller_attribute_value_in_shop_succeed: 'Update attribute value in shop succeed',
      update_woo_controller_attribute_value_in_shop_failed: 'Update attribute value in shop failed',

      delete_woo_controller_attribute_value_in_shop_succeed: 'Delete attribute value in shop succeed',
      delete_woo_controller_attribute_value_in_shop_failed: 'Delete attribute value in shop failed',

      woo_controller_attribute_transferred: 'Attribute transferred'
    }
  },

  errors: {
    invoices: {
      useral_invoice_id: 'Useral invoice id: {id}',
      invoice_user_not_received: 'Invoice user not received',
      invoice_user_has_technical_problem: 'Invoice user has technical problem',
      invoice_user_not_existed_in_useral: 'Invoice user not existed in Useral',
      duplicated_invoice_number: 'Duplicated invoice number',
      product_variant_technical_error: 'Variant with barcode {barcode} has technical error',
      product_variant_not_transfer_to_useral: 'Variant with barcode {barcode} not transfer to Useral',
      invoice_payment_not_existed: 'Invoice payment not existed',
      empty_invoice_payment_number: 'Empty invoice payment number',
      duplicated_invoice_payment_number: 'Duplicated invoice payment number',
      delete_old_sale_invoices: '{count} old pre sale invoices deleted',
      delete_old_purchase_invoices: '{count} old pre purchase invoices deleted',
      delete_old_return_sale_invoices: '{count} old pre return sale invoices deleted',
      delete_old_return_purchase_invoices: '{count} old pre return purchase invoices deleted',
    },

    users: {
      useral_user_id: 'Useral user id: {id}',
      empty_user_name: 'Empty user name',
      empty_user_phone: 'Empty user phone',
      invalid_user_phone: 'Invalid user phone',
      invalid_user_email: 'Invalid user email',
      duplicated_user_phone_in_useral: 'Duplicated user phone in useral',
      duplicated_user_phone_in_shop: 'Duplicated user phone in shop',
      duplicated_user_email_in_useral: 'Duplicated user email in useral',
      duplicated_user_email_in_shop: 'Duplicated user email in shop',
    },

    products: {
      invalid_product_manage_stock: 'Invalid product manage stock',
      active_product_back_ordered: 'Active product back ordered',
      empty_product_variants: 'Empty product variants',
      invalid_product_variant_attribute: 'Invalid product variant attribute',
      empty_product_variant_barcode: 'Empty product variant barcode',
      duplicated_product_variant_barcode: 'Duplicated product variant barcode',
      invalid_product_variant_barcode: 'Invalid product variant barcode',
      duplicated_product_code: 'Duplicated product code',
      disable_product_variant_mange_stock: 'Disable product variant mange stock',
      active_product_variant_back_ordered: 'Active product variant back ordered',
      empty_product_variant_attributes: 'Empty product variant attributes',
      useral_product_id: 'Useral product id: {id}',
      useral_product_variant_id: 'Useral product variant id: {id}'
    }
  }
}
