export default {
  subject: {
    // delete old pre-invoices
    delete_old_sale_invoices: 'پیش فاکتورهای فروش قدیمی حذف شد',
    delete_old_purchase_invoices: 'پیش فاکتورهای خرید قدیمی حذف شد',
    delete_old_return_sale_invoices: 'پیش فاکتورهای مرجوع فروش قدیمی حذف شد',
    delete_old_return_purchase_invoices: 'پیش فاکتورهای مرجوع خرید قدیمی حذف شد',
    delete_old_sale_invoices_dynamic: '{count} پیش فاکتورهای فروش قدیمی حذف شد',
    delete_old_purchase_invoices_dynamic: '{count} پیش فاکتورهای خرید قدیمی حذف شد',
    delete_old_return_sale_invoices_dynamic: '{count} پیش فاکتورهای مرجوع فروش قدیمی حذف شد',
    delete_old_return_purchase_invoices_dynamic: '{count} پیش فاکتورهای مرجوع خرید قدیمی حذف شد',

    invoices: {
      woo_controller_invoice_technical_problem: 'اطلاعات فاکتور دارای مشکل فنی می باشد',
      woo_controller_invoice_technical_problem_dynamic: 'اطلاعات فاکتور با شناسه {id} دارای مشکل فنی می باشد',
      woo_controller_invoice_transferred: 'اطلاعات فاکتور با موفقیت به یوزرال منتقل شد',
      woo_controller_invoice_updated: 'اطلاعات فاکتور با موفقیت در یوزرال بروزرسانی شد',

      create_insert_order_webhook_succeed: 'وب هوک افزودن فاکتور در ووکامرس با موفقیت ایجاد شد',
      create_insert_order_webhook_failed: 'ایجاد وب هوک افزودن فاکتور در ووکامرس با خطا همراه شد',

      create_update_order_webhook_succeed: 'وب هوک ویرایش فاکتور در ووکامرس با موفقیت ایجاد شد',
      create_update_order_webhook_failed: 'ایجاد وب هوک ویرایش فاکتور در ووکامرس با خطا همراه شد',

      update_woo_controller_order_status_in_shop_succeed: 'وضعیت فاکتور فروش با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_order_status_in_shop_failed: 'بروزرسانی وضعیت فاکتور فروش در فروشگاه با خطا همراه شد'
    },
    users: {
      woo_controller_user_technical_problem: 'اطلاعات شخص دارای مشکل فنی می باشد',
      woo_controller_user_technical_problem_dynamic: 'اطلاعات شخص با شناسه {id} دارای مشکل فنی می باشد',

      delete_woo_controller_user_from_shop_succeed: 'اطلاعات شخص از فروشگاه با موفقیت حذف شد',
      delete_woo_controller_user_from_shop_failed: 'حذف اطلاعات شخص از فروشگاه با خطا همراه شد',

      create_woo_controller_user_in_shop_success: 'ایجاد اطلاعات کاربر در فروشگاه با موفقیت انجام شد',
      create_woo_controller_user_in_shop_failed: 'ایجاد اطلاعات کاربر در فروشگاه با خطا همراه شد',

      update_woo_controller_user_in_shop_success: 'اطلاعات شخص با موفقیت در فروشگاه ویرایش شد',
      update_woo_controller_user_in_shop_failed: 'ویرایش اطلاعات شخص در فروشگاه با خطا همراه شد',

      delete_woo_controller_user_in_shop_success: 'اطلاعات شخص در فروشگاه با موفقیت حذف شد',
      delete_woo_controller_user_in_shop_failed: 'حذف اطلاعات شخص در فروشگاه با خطا همراه شد',

      update_woo_controller_user_in_shop_succeed: 'اطلاعات شخص در فروشگاه با موفقیت ویرایش شد',

      create_insert_customer_webhook_succeed: 'وب هوک افزودن مشتری با موفقیت ایجاد شد',
      create_insert_customer_webhook_failed: 'ایجاد وب هوک افزودن مشتری با خطا همراه شد',

      create_update_customer_webhook_succeed: 'وب هوک ویرایش مشتری با موفقیت ایجاد شد',
      create_update_customer_webhook_failed: 'ایجاد وب هوک ویرایش مشتری با خطا همراه شد',

      create_delete_customer_webhook_succeed: 'وب هوک حذف مشتری با موفقیت ایجاد شد',
      create_delete_customer_webhook_failed: 'ایجاد وب هوک حذف مشتری با خطا همراه شد',
    },
    products: {
      woo_controller_product_variant_technical_problem: 'اطلاعات متغیر دارای مشکل فنی می باشد',
      woo_controller_product_technical_problem: 'اطلاعات کالا دارای مشکل فنی می باشد',

      woo_controller_product_variant_technical_problem_dynamic: 'اطلاعات متغیر با بارکد {barcode} دارای مشکل فنی می باشد',
      woo_controller_product_technical_problem_dynamic: 'اطلاعات کالا با بارکد {barcode} دارای مشکل فنی می باشد',

      delete_woo_controller_product_from_shop_failed: 'حذف اطلاعات کالا در فروشگاه با خطا همراه شد',
      delete_woo_controller_product_variant_from_shop_failed: 'حذف اطلاعات متغیر در فروشگاه با خطا همراه شد',

      restore_woo_controller_product_in_shop_success: 'اطلاعات کالا با موفقیت در فروشگاه بازگردانی شد',
      restore_woo_controller_product_in_shop_failed: 'بازگردانی اطلاعات کالا در فروشگاه با خطا همراه شد',

      create_woo_controller_product_in_shop_success: 'اطلاعات کالا با موفقیت در فروشگاه ثبت شد',
      create_woo_controller_product_in_shop_failed: 'ثبت اطلاعات کالا در فروشگاه با خطا همراه شد',

      update_woo_controller_product_in_shop_success: 'اطلاعات کالا با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_product_in_shop_failed: 'بروزرسانی اطلاعات کالا در فروشگاه با خطا همراه شد',

      create_woo_controller_product_variant_in_shop_success: 'اطلاعات متغیر با موفقیت در فروشگاه ایجاد شد',
      create_woo_controller_product_variant_in_shop_failed: 'ایجاد اطلاعات متغیر در فروشگاه با خطا همراه شد',

      update_woo_controller_product_variant_in_shop_success: 'اطلاعات متغیر با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_product_variant_in_shop_failed: 'ویرایش اطلاعات متغیر کالا در فروشگاه با خطا همراه شد',

      woo_controller_product_updated_in_useral: 'اطلاعات کالا با موفقیت در یوزرال بروزرسانی شد.',
      woo_controller_product_variant_updated_in_useral: 'اطلاعات متغیر با موفقیت در یوزرال بروزرسانی شد.',

      woo_controller_product_variant_transferred: 'متغیر کالا منتقل شد.',

      deleted_woo_controller_product_variant_from_shop: 'اطلاعات متغیر از فروشگاه حذف شده است',

      create_insert_product_webhook_succeed: 'وب هوک افزودن کالا با موفقیت ایجاد شد',
      create_insert_product_webhook_failed: 'ایجاد وب هوک افزودن کالا با خطا همراه شد',

      create_update_product_webhook_succeed: 'وب هوک ویرایش کالا با موفقیت ایجاد شد',
      create_update_product_webhook_failed: 'ایجاد وب هوک ویرایش کالا با خطا همراه شد',

      create_delete_product_webhook_succeed: 'وب هوک حذف کالا با موفقیت ایجاد شد',
      create_delete_product_webhook_failed: 'ایجاد وب هوک حذف کالا با خطا همراه شد',

      update_woo_controller_product_stock_in_shop_succeed: 'موجودی کالا با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_product_stock_in_shop_failed: 'بروزرسانی موجودی کالا در فروشگاه با خطا همراه شد',

      update_woo_controller_product_variant_stock_in_shop_succeed: 'موجودی متغیر با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_product_variant_stock_in_shop_failed: 'بروزرسانی موجودی متغیر در فروشگاه با خطا همراه شد',

      update_woo_controller_product_variant_price_in_shop_succeed: 'قیمت متغیر با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_product_variant_price_in_shop_failed: 'بروزرسانی قیمت متغیر در فروشگاه با خطا همراه شد',

      woo_controller_product_price_updated_from_shop: 'قیمت کالا با موفقیت از فروشگاه دریافت شد',
      woo_controller_product_stock_updated_from_shop: 'موجودی کالا با موفقیت از فروشگاه دریافت شد',
      woo_controller_product_variant_stock_updated_from_shop: 'موجودی متغیر با موفقیت از فروشگاه دریافت شد',

      update_woo_controller_product_price_in_shop_succeed: 'قیمت کالا با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_product_price_in_shop_failed: 'بروزرسانی قیمت کالا در فروشگاه با خطا همراه شد',
      deleted_woo_controller_product_from_shop: 'اطلاعات کالا از فروشگاه حذف شده است',
      woo_controller_product_transferred: 'اطلاعات کالا با موفقیت به یوزرال منتقل شد'
    },
    attributes: {
      create_woo_controller_attribute_value_in_shop_succeed: 'اطلاعات مقادیر ویژگی با موفقیت در فروشگاه اضافه شد',
      create_woo_controller_attribute_value_in_shop_failed: 'افزودن اطلاعات مقادیر ویژگی در فروشگاه با خطا همراه شد',

      update_woo_controller_attribute_value_in_shop_succeed: 'اطلاعات مقادیر ویژگی با موفقیت در فروشگاه بروزرسانی شد',
      update_woo_controller_attribute_value_in_shop_failed: 'بروزرسانی اطلاعات مقادیر ویژگی در فروشگاه با خطا همراه شد',

      delete_woo_controller_attribute_value_in_shop_succeed: 'اطلاعات مقادیر ویژگی با موفقیت از فروشگاه حذف شد',
      delete_woo_controller_attribute_value_in_shop_failed: 'حذف اطلاعات مقادیر ویژگی از فروشگاه با خطا همراه شد',

      woo_controller_attribute_transferred: 'اطلاعات ویژگی با موفقیت به یوزرال منتقل شد'
    }
  },

  errors: {
    invoices: {
      useral_invoice_id: 'شناسه فاکتور در یوزرال: {id}',
      invoice_user_not_received: 'اطلاعات مشتری دریافت نشده است',
      invoice_user_has_technical_problem: 'اطلاعات مشتری دارای مشکل فنی می باشد',
      invoice_user_not_existed_in_useral: 'اطلاعات مشتری در یوزرال وجود ندارد',
      duplicated_invoice_number: 'فاکتوری با این شماره در یوزرال موجود می باشد',
      product_variant_technical_error: 'اطلاعات متغیر با بارکد {barcode} دارای مشکل فنی می باشد',
      product_variant_not_transfer_to_useral: 'اطلاعات متغیر با بارکد {barcode} به یوزرال منتقل نشده است',
      invoice_payment_not_existed: 'هیچ رسید پرداختی برای فاکتور ثبت نشده است',
      empty_invoice_payment_number: 'شماره تراکنش رسید پرداخت برای فاکتور وجود ندارد',
      duplicated_invoice_payment_number: 'شماره تراکنش رسید پرداخت برای فاکتور تکراری است',
      delete_old_sale_invoices: '{count} پیش فاکتور فروش قدیمی حذف شد',
      delete_old_purchase_invoices: '{count} پیش فاکتور خرید قدیمی حذف شد',
      delete_old_return_sale_invoices: '{count} پیش فاکتور مرجوع فروش قدیمی حذف شد',
      delete_old_return_purchase_invoices: '{count} پیش فاکتور مرجوع خرید قدیمی حذف شد',
    },

    users: {
      useral_user_id: 'شناسه شخص در یوزرال: {id}',
      empty_user_name: 'نام شخص وارد نشده است',
      empty_user_phone: 'شماره همراه شخص وارد نشده است',
      invalid_user_phone: 'شماره همراه شخص معتبر نیست',
      invalid_user_email: 'ایمیل شخص معتبر نیست',
      duplicated_user_phone_in_useral: 'شماره همراه شخص در یوزرال تکراری است',
      duplicated_user_phone_in_shop: 'شماره همراه شخص در فروشگاه تکراری است',
      duplicated_user_email_in_useral: 'ایمیل شخص در یوزرال تکراری است',
      duplicated_user_email_in_shop: 'ایمیل شخص در فروشگاه تکراری است',
    },

    products: {
      invalid_product_manage_stock: 'مدیریت موجودی کالا با نوع کالا همخوانی ندارد',
      active_product_back_ordered: 'پیش فروش کالا فعال است',
      empty_product_variants: 'هیچ متغیری برای کالا موجود نیست',
      invalid_product_variant_attribute: 'برای کالای متغیر فقط یک ویژگی میتواند متغیر باشد',
      empty_product_variant_barcode: 'بارکد متغیر موجود نیست',
      duplicated_product_variant_barcode: 'بارکد متغیر تکراری است',
      invalid_product_variant_barcode: 'بارکد فقط شامل اعداد می باشد',
      duplicated_product_code: 'کد کالا تکراری است',
      disable_product_variant_mange_stock: 'مدیریت موجودی متغیر غیرفعال است',
      active_product_variant_back_ordered: 'پیش فروش متغیر فعال است',
      empty_product_variant_attributes: 'هیچ ویژگی برای متغیر انتخاب نشده است',
      useral_product_id: 'شناسه کالا در یوزرال: {id}',
      useral_product_variant_id: 'شناسه متغیر در یوزرال: {id}'
    }
  }
}
